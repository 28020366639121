import React from 'react'
import FormMain from '../../components/form/FormMain'
import Footer from '../../components/footer/Footer'
import Header from '../../components/merchantLanding/header/Header'

function CallToAction() {
  return (
    <div>
        <Header />
        <FormMain /> 
        
        <Footer />
    </div> 
  )
}

export default CallToAction