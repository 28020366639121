import React from 'react'
import Footer from '../../components/footer/Footer'
import HeroTerms from '../../components/terms/HeroTerms'
import TermSideMenu from '../../components/terms/TermSideMenu'
import { Link } from 'react-router-dom'
import Header from '../../components/merchantLanding/header/Header'

function Privacy() {
   
  return (
    <div>
      <Header />
    
      <HeroTerms 
      subtitle="Last modified: April, 2023"
      title="Privacy Policy"
      description="Learn more about how Flick collects and uses data and your rights as a Flick user."
      />
      <div className='terms-condition-main'>
        <div className="terms-condition-nav">
            <TermSideMenu />
        </div>
        
        <div className='terms-condition-main-content'>
            <section className='terms-sec'  id="introduction">
            <h1>Introduction</h1>
            <p>
            QRaba Inc. and its subsidiaries/affiliates (hereinafter called “Flick” / “We” / “us” / “our”), treat your personal information as private and confidential. We are dedicated to protecting your privacy and providing you with the highest level of security at any point of interaction with us.
            This Privacy Policy describes what personal information we collect, what we do with it and how we protect it.This policy (together with our Terms and Conditions) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us.
            Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By continuing to visit our website (www.getflick.app) and other Flick applications and solutions, you accept and consent to the practices described in this policy
            </p>

            <h1>Definitions</h1>
            <div>
              <h5 className='def-title'>
                Account: <span className='def-text'>means a Flick User or Business Account</span>
              </h5>
              <h5 className='def-title'>
                Cookies: 
                <span className='def-text'>
                A cookie is a small data file that is transferred to your computer or mobile device. It enables us to remember your account log-in information, IP addresses, web traffic, number of times you visit, browser type and version, device details, date and time of visits
                </span>
              </h5>
              <h5 className='def-title'>
              Flick: <span className='def-text'>QRaba Inc. or affiliated companies collectively referred to as “Flick”</span>
              </h5>
              <h5 className='def-title'>
              Personal Information: 
              <span className='def-text'>
                Any information that can be used to identify a living person including email address, company name, password, payment card, financial information such as Bank Verification Number (BVN), bank account number, etc.), Government-issued Identity card, and/or taxpayer identification it may also include anonymous information that is linked to you, for example, your internet protocol (IP), log-in information, address, location, device or transaction data.
              </span>
              </h5>
              <h5 className='def-title'>
              Sites: 
              <span className='def-text'>
              means any platform including but not limited to mobile applications, websites and social media platforms.
              </span>
              </h5>
              <h5 className='def-title'>
              User: 
              <span className='def-text'>
                means an individual or business who uses the Services or accesses the Sites and has agreed to use the end services of Flick.
              </span>
              </h5>
              <p>Special Categories of Personal Information means details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data.</p>
            </div>
            </section>

            <section className='terms-sec'  id="information">
            <h1 className='policy-mb'>Personal Information We May Collect About You</h1>
            <p>We may collect, use, process, store, or transfer personal information such as:</p>
            <p>
            <li>
                Identity Data: Information such as, your full name(s), your government-issued identity number, and your date of birth. This data is to enable us to verify your identity in order to offer our services to you;
            </li>
            <li>
            In order to experience the full range of Flick's 
            seamless services including the ability to 
            send funds to your phone contacts through Flick, 
            we would require your consent granting access to 
            your geo-location and your contacts. You have the 
            right to withdraw your consent allowing us access 
            to your contacts at any time. Please note that where 
            your consent is not given or withdrawn, we would require 
            the email address or contact of the recipient, in order to 
            facilitate the fund transfer through Flick.
            </li>
            <li>
             Contact Data: This is data that is needed to reach out to you, such as your contact address, email address, telephone number, details of the device you use and billing details;
            </li>
            <li>
              Identification documents :(such as your passport or any Government-issued identity card), a photograph (if applicable) and any other registration information you may provide to prove you are eligible to use our services and in compliance with regulatory requirements on Know Your Customer (KYC);
            </li>
            <li>     
             Log/Technical information: When you access Flick services, our servers automatically record information that your browser sends whenever you visit a website, links you have clicked on, length of visit on certain pages, unique device identifier, log-in information, location and other device details.
            </li>
            <li>     
             Financial Data: Information, such as Bank Verification Number (BVN), personal account number, the merchant’s name and location, the date and the total amount of transaction, and other information provided by financial institutions or merchants when we act on their behalf;
            </li>
            <li>  
            Transactional Data: These are information relating to a payment when you as a merchant (using one or more of our payment processing services) or as a customer, are using our products or services;   
            </li>
            <li>     
                Marketing and Communications Data: This includes both a record of your decision to subscribe or to withdraw from receiving marketing materials from us or from our third parties.
            </li>
            <li>     
            Records of your discussions with us, if we contact you and if you contact us.
            We may also collect, store, use and transfer non-personal information or anonymized data such as statistical or demographic data.
            </li>
               
            </p>

            <p>
            As a principle, we do not collect any Special Categories of Personal Information. If we do collect Special Categories of Personal Information, we will ensure compliance with applicable law.
            This Privacy Policy applies to Flick services only. We do not exercise control over the sites displayed or linked from within our various services. These other sites may place their own cookies, plug-ins or other files on your computer, collect data or solicit personal information from you. Flick does not control these third-party websites and we are not responsible for their privacy statements. Please consult such third parties’ privacy statements and set appropriate controls from your setting when using any third-party services.
            </p>

            </section>

            <section className='terms-sec'  id="information">
            <h1 className='policy-mb'>How and why we collect your Personal Information</h1>

            <p>
                The Personal Information we have about you is directly made available to us when you:
            </p>
            <p>
            <li>
                Sign up for a Flick Account (either on the App, web or through our API platforms)
            </li>
            <li>
            Use any of our services
            </li>
            <li>
            Contact us, our customer support team or fill our online forms
            </li>

            The lawful basis we rely on for processing your Personal Information are:
            <li>
            Your consent: where you agree to us collecting your Personal Information by using our Services.
            </li>
            <li>
                We have a contractual obligation: without your Personal Information, we cannot provide our Services to you.
            </li>
            <li>
            We have a legal obligation: to ensure we are fully compliant with all applicable Financial legislation such as Anti-Money Laundering and Counter Terrorist Financing Laws, we must collect and store your Personal Information. We protect against fraud by checking your identity with your Personal Information.
            </li>
            </p>
            </section>

            <section className='terms-sec'  id="how-why">
            <h1 className='policy-mb'>How We Use Your Personal Information</h1>
            <p>
            We may use your Personal Information we collect to:
            <li>
                Create and manage any accounts you may have with us, verify your identity, provide our services, and respond to your inquiries;
            </li>
            <li>
            Process your payment transactions (including authorization, clearing, chargebacks and other related dispute resolution activities);
            </li>
            <li>
            Protect against and prevent fraud, unauthorized transactions, claims and other liabilities;
            </li>
            <li>
            Provide, administer and communicate with you about products, services, offers, programs and promotions of Flick, financial institutions, merchants and partners;
            </li>
            <li>
            Evaluate your interest in employment and contact you regarding possible employment with Flick
            </li>
            <li>
            Evaluate and improve our business, including developing new products and services;
            </li>
            <li>
            To target advertisements, newsletter and service updates;
            </li>
            <li>
            As necessary to establish, exercise and defend legal rights;
            </li>
            <li>
            As may be required by applicable laws and regulations, including for compliance with Know Your Customers and risk assessment, Anti-Money Laundering, anti-corruption and sanctions screening requirements, or as requested by any judicial process, law enforcement or governmental agency having or claiming jurisdiction over Flick or Flick’s affiliates;
            </li>
            <li>
            To use data analytics to improve our Website, products or services, user experiences and to optimize service.
            </li>
            <li>
            For other purposes for which we provide specific notice at the time of collection.
            </li>
            
            </p>
            </section>
            
            <section className='terms-sec'  id="disclosing">
            <h1 className='policy-mb'>
                Disclosing your Personal Information
            </h1>
            <p className='policy-mb'>We may disclose or share your Personal Information with third parties which include our affiliates, employees, officers, service providers, agents, suppliers, subcontractors as may be reasonably necessary for the purposes set out in this policy.</p>
            <p className='policy-mb'>Flick only shares personal information with External Third parties in the following limited circumstances:</p>
            <p>
            
            <li>
            We have your consent. We require opt-in consent for the sharing of any personal information; We share Personal Information with third parties directly authorized by you to receive Personal Information, such as when you authorize a third party application provider to access your account. The use of your Personal Information by an authorized third party is subject to the third party's privacy policy and Flick shall bear no liability for any breach which may arise from such authorization by you.
            </li>
            <li>
            We provide such information to our subsidiaries, affiliated companies or other trusted businesses or persons for the purpose of processing personal information on our behalf. We require that these parties agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.
            </li>
            
            <li>
            We have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary to:
            </li>
            
            <ol>
                <li>satisfy any applicable law, regulation, legal process or enforceable governmental request,</li>
                <li>enforce applicable Terms of Service, including investigation of potential violations thereof,</li>
                <li>detect, prevent, or otherwise address fraud, security or technical issues, or</li>
                <li>protect against imminent harm to the rights, property or safety of Flick, its users or the public as required or permitted by law.</li>
            </ol>
            
            
            <li>
            To a buyer or other successor in the event of a merger, acquisition, divestiture, restructuring, reorganisation, dissolution, or other sale or transfer of some or all of Flick's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Flick about our users is among the assets transferred.
            </li>
            </p>
            </section>


            <section className='terms-sec'  id="security">
            <h1 className='policy-mb'>
            Data Security and Retention
            </h1>
            <p >The security of your Personal Information is important to us. We are committed to protecting the information we collect. We maintain administrative, technical and physical controls designed to protect the Personal Information you provide, or we collect against loss or theft, as well as against any unauthorized access, risk of loss, disclosure, copying, misuse or modification.</p>
            <p>Other security measures include but not limited to, secure servers, firewall, data encryption and granting access only to employees in order to fulfil their job responsibilities.</p>
            <p>Where you use a password for any of your Accounts, please ensure you do not share this with anyone, and the password is kept confidential at all times.</p>

           <p>We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of your Personal Information is protected and maintained. Transmitting information online is not entirely secure. As such, we cannot guarantee that all information provided online is secure. We would take all reasonable steps to ensure that your Personal Information is secured and well protected.</p>
           <p>We will only retain personal information on our servers for as long as is reasonably necessary as long as we are providing Services to you. Where you close your Account, your information is stored on our servers to the extent necessary to comply with regulatory obligations and for the purpose of fraud monitoring, detection and prevention. Where we retain your Personal Information, we do so in compliance with limitation periods under the applicable law.</p>

            </section>

            <section className='terms-sec'  id="choice">
            <h1 className='policy-mb'>
            Marketing
            </h1>
            <p>
            We may process your Personal Information in order to contact you or send you marketing content and communication about our products, services or surveys. You may exercise your right to object to such contact from us or opt out from the marketing content. Please note however that if you opt-out
            of marketing content, we may still send you messages relating to transactions and our Services related to our ongoing business relationship.
            </p>
            <p>
            Promotion by the Company. If you do not want us to use your email address/ contact information to promote our own or third parties' products or services, you can opt-out at any time via our <Link to="/">web-form</Link>  or send us an email at 
             {/* eslint-disable-next-line */}
            <a href="#">hello@getflick.app</a>
            </p>
            <p>We may ask you for permission to send notifications to you. Our Services will still work if you do not grant us consent to send you notifications.</p>
            </section>

            <section className='terms-sec'  id="right">
            <h1 className='policy-mb'>
            Your rights as a Data Subject
            </h1>
            <p>
            Based on your location and applicable laws, below are the rights you have as a user in relation to your Personal Information:
            </p>
            <li>Right to be informed</li>
            <li>Right to request access or copies to your Personal Information by signing into your Account or contacting us.</li>
            <li>Right to request that Flick erase your Personal Information. You have the right to ask us to erase your Personal Information. Please note that this is a limited right which applies where the data is no longer required, or the processing has no legal justification. The exceptions to this right is where the applicable law requires Flick to retain a historical archive or where we retain a core set of your Personal Information to ensure we do not inadvertently contact you in future where you object to your data being used for marketing purposes.</li>
            <li>Right to correct or rectify any Personal Information that you provide which may be incorrect, out of date or inaccurate. You also have the right to ask us to complete information you think is incomplete.</li>
            <li>Right to object to the processing of your Personal Information for marketing purposes. You have a right to ask us not to contact you for marketing purposes by adjusting your notification preference on the settings page or by opting out via the unsubscribe link in marketing emails we send you.</li>
            <li>Right to object to processing: You have the right to object to the processing of your Personal Information in certain circumstances. Please note that where you object to us processing your Personal Information, we might be unable to provide the services to you.</li>
            <p>
            The basis of we processing your Personal Information is your consent; you have the choice at any time to withdraw consent which you have provided. You also have a choice to de-activate or delete your Account at any time; you can do so by logging into the App and closing your account via your profile settings or this <Link to="/">web-form</Link> 
            or send us an email at 
             {/* eslint-disable-next-line */}
            <a href='#'>hello@getflick.app.</a>
            </p>
            <p>If you wish to exercise any of the rights set above, please contact us; where we are unsure of your identity, we might ask you for proof of your identity for security reasons before dealing your request.</p>
           
            </section>

            <section className='terms-sec'  id="cookies">
            <h1 className='policy-mb'>
            Cookies
            </h1>
            <p>
            Flick may use cookies, web beacons, tracking pixels, and other tracking technologies when you visit our website, including any other media form, media channel, mobile website, or mobile application
            related or connected thereto (collectively, the “Site”) to help customize the Site and improve your experience.
            <br/>
            We reserve the right to make changes to this Cookie Policy at any time and for any reason. Any changes or modifications will be effective immediately upon posting the updated Cookie Policy on the Site, and you waive the right to receive specific notice of each such change or modification.
            You are encouraged to periodically review this Cookie Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Cookie Policy by your continued use of the Site after the date such revised Cookie Policy is posted.
            <br/>
            Like many other websites, we use cookies to distinguish you from other users, customize and keep track of services you have used, record registration information, record your user preferences, keep you logged into the Site, facilitate purchase procedures, and track the pages you visit. Cookies help us understand how the Site is being used and improve your user experience
            <br/><br/>
            Some browsers may automatically accept cookies while some can be modified to decline cookies or alert you when a website wants to place a cookie on your computer. If you do choose to disable cookies, it may limit or affect the availability and functionality of the Site.
            <br/><br/>
            In addition to cookies, we may use web beacons, pixel tags, and other tracking technologies on the Site to help customize the Site and improve your experience. A “web beacon” or “pixel tag” is tiny object or image embedded in a web page or email. They are used to track the number of users who have visited particular pages and viewed emails, and acquire other statistical data. They collect only a limited set of data, such as a cookie number, time and date of page or email view, and a description of the page or email on which they reside. Web beacons and pixel tags cannot be declined. However, you can limit their use by controlling the cookies that interact with them.
            </p>
            </section>
            <section className='terms-sec'  id="choice">
            <h1 className='policy-mb'>
            Minor
            </h1>
            <p>
            Flick’s websites and applications are not directed at persons under the age of eighteen (18) and we do not collect any Personal Information knowingly or directly from minors who fall within this category.
            Where you have any belief that Flick has mistakenly or unknowingly collected information from a minor, please contact us via privacy@getflick.app to investigate and restrict such data.
            </p>
            </section>
            <section className='terms-sec'  id="choice">
            <h1 className='policy-mb'>
            International Data Transfers
            </h1>
            <p>
            Where Personal Information is to be transferred to a country outside Nigeria, Flick shall put adequate measures in place to ensure the security of such Personal Information and to ensure same is done securely and in accordance with the Nigerian Data Protection Regulation (NDPR) and the General Data Protection Regulation (GDPR)
            </p>
            </section>
            <section className='terms-sec'  id="dpo">
            <h1 className='policy-mb'>
            Data Protection Officer
            </h1>
            <p>
            Flick has appointed a Data Protection Officer(s) (DPO) responsible for overseeing the Company's data protection strategy and its implementation to ensure compliance with the Data Protection Regulations requirements. The DPO is knowledgeable on data privacy and protection principles and is familiar with the provisions of the NDPR and GDPR.
            </p>
            </section>
            <section className='terms-sec'  id="choice">
            <h1 className='policy-mb'>
            Training
            </h1>
            <p>
            Flick ensures that employees who collect, access and process Personal Information receive adequate data privacy and protection training in order to develop the necessary knowledge, skills and competence required to effectively manage the compliance framework under this Policy and the Nigerian Data Protection Regulation (NDPR) with regard to the protection of Personal Information. On an annual basis, Flick develops a capacity building plan for its employees on data privacy and protection in line with the NDPR
            </p>
            </section>

            <section className='terms-sec'  id="choice">
            <h1 className='policy-mb'>
            Data Protection Audit
            </h1>
            <p>
                <li>Flick shall conduct an annual data protection audit through a licensed Data Protection Compliance Organization (DPCOs) to verify Flick’s compliance with the provisions of the NDPR and other applicable data protection laws.</li>
                <li>The audit report will be certified and filed by the DPCO to the National Information Technology Development Agency (NITDA) as required under the NDPR.</li>
            </p>
            </section>
            <section className='terms-sec'  id="choice">
            <h1 className='policy-mb'>
            Updates to our privacy policy
            </h1>
            <p>
            From time to time, we may change, amend or review this Privacy Policy from time to time to reflect new services or changes in our Privacy Policy and place any updates on this page. All changes made will be posted on this page and where changes will materially affect you, we will notify you of this change by placing a notice online or via mail. If you keep using our Services, you consent to all amendments of this Privacy Policy.
            </p>
            </section>
            <section className='terms-sec'  id="choice">
            <h1 className='policy-mb'>
            Contact us:
            </h1>
            <p>
            All access requests, questions, comments, complaints and other requests regarding the privacy policy should be sent to 
            {/*  eslint-disable-next-line */}
            <a href='#'>privacy@getflick.app.</a>
            We may request additional details from you regarding your complaints and keep records of your requests and resolution.
            </p>
            </section>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Privacy
