import React, {useRef, useState} from 'react';

import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import './Form.scss'

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Your Message has been successfully sent.
        </Alert>
    )
}

const FormPosition = ({toggleModel}) => { 

    const form = useRef();
    // eslint-disable-next-line
    const [ result, showresult ] = useState(false);
    const [file,setFile] = useState("")
    // eslint-disable-next-line
    const [inputValues,setInputValues] = useState({fullname:"",email:"",phone:""})
    // eslint-disable-next-line
    const formData = new FormData()
    const handleFile =(e)=>{
        setFile(e.target.files[0]);
        console.log(e.target.files[0])
    }

    const handleChange =(e)=>{
        setInputValues({
            [e.target.name] : e.target.value
        })
        
    }

    const sendEmail = (e) => {
        e.preventDefault();
        // eslint-disable-next-line
        const file = file; // Get the attached file
        const formData = new FormData();
        formData.append('file', file); 

        emailjs.sendForm('service_mefikuk', 'template_uf7jx9d', e.target, 'g9OMWF1jW-LCzDVkO')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          form.current.reset();
          showresult(true);

          
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    const handleModel = (e)=>{
            
       toggleModel();
    }
 
    return ( 
        
            <div className='modalform'>
                <div className='overlay'>
                </div>    
                    <form ref={form} onSubmit={sendEmail} className='form-position-container' >
                        <h1>Marketing position</h1>
                        <div className="form-group1">
                            <label className='lblHeading'>fullName *</label>
                            <input type="text" className="form-control" name="fullname" placeholder="fullname" 
                            onChange={handleChange}
                            required 
                            />
                        </div>
                        <div className="form-group1">
                            <label className='lblHeading'>Email Address *</label> 
                            <input type="text" className="form-control" name="email" 
                            placeholder="example@gmail.com" 
                            required 
                            onChange={handleChange}
                            />
                        </div>
                        <div className="form-group1">
                            <label className='lblHeading'>phone *</label>
                            <input type="text" className="form-control" name="phone" 
                            placeholder="08012345678" 
                            required 
                            onChange={handleChange}
                            />
                        </div>
                    
                        <div className='upload-cv-container'>
                            <p>Resume</p>
                            <label htmlFor="cv" className='lbl-cv'>
                                <img src={process.env.PUBLIC_URL+"/images/uploadicon.svg"} alt='upload-img' />
                                <span>add file</span>
                            </label>
                            <input 
                            type='file' 
                            name="attachment" 
                            id='cv' 
                            className='input-cv' 
                            required style={{display:"none",visibility:"none"}}  
                            onChange={handleFile}
                            />
                            {file.name}
                        </div>
                        <div className="form-group btnsumbit-container">
                            <button 
                            type="submit" 
                            className="axil-btn btn-fill-black btn-subumit" 
                            name="submit-btn"
                            onClick={handleModel}
                            >
                                Cancel
                            </button>

                            <button type="submit" className="axil-btn btn-fill-primary  btn-subumit" name="submit-btn">Submit form</button>
                        </div> 
                        <div className="form-group">
                            {result ? <Result /> : null}
                        </div>

                    </form>
                
            </div>
                
            

            
             

            
        
    )
}

export default FormPosition;