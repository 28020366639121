import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import './Form.scss'

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Your Message has been successfully sent.
        </Alert>
    )
}

const OtherRoles = ({toggleOtherRole}) => { 

    const form = useRef();

    const [ result, showresult ] = useState(false);
    // eslint-disable-next-line
    const [selectedOption, setSelectedOption] = useState('');
    // eslint-disable-next-line
    const [selectedRole, setSelectedRole] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_yj5dgzp', 'template_hfduayo', form.current, 'WLENsTkBytC0yvItS')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          form.current.reset();
          showresult(true);

          
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    const handleModel = (e)=>{
        
        toggleOtherRole();
    }

    // eslint-disable-next-line
    const handleOptionChange = (event) => {
            setSelectedOption(event.target.value);
    };
 
    return ( 
        
            <div className='modalform'>
                <div className='overlay'>
                </div>    
                    <form ref={form} onSubmit={sendEmail} className='form-position-container' >
                        
                        <div className="form-group1">
                            <label className='lblHeading'>fullName *</label>
                            <input type="text" className="form-control" name="contact-name" placeholder="fullname" required />
                        </div>
                        <div className="form-group1">
                            <p className='lblHeading'>Position</p> 
                            <label className='other-position'>
                                <input
                                type="radio"
                                value="Full time"
                                // checked={selectedOption === 'Full time'}
                                // onChange={handleOptionChange}
                                />
                                <span>Full time</span>
                                
                            </label>
                            
                            <label className='other-position'>
                                <input
                                type="radio"
                                value="internship"
                                // checked={selectedOption === 'internship'}
                                // onChange={handleOptionChange}
                                />
                                <span>internship</span>
                            </label>
                        </div>
                        <div className="form-group1">
                            <label className='lblHeading'>Role</label>
                            &nbsp;
                            <select value={selectedRole} onChange={handleSelectChange}>
                                <option value="">-- Select --</option>
                                <option value="Design">Design</option>
                                <option value="data analysis">data analysis</option>
                                <option value="product management">product management</option>
                                <option value="software engineering">software engineering</option>
                                <option value="marketing">marketing</option>
                                <option value="operations">operations</option>
                                <option value="other">other</option>     
                            </select>
                        </div>
                        <div className='form-group1'>
                            <label>Linkedin/Portfolio URL</label>
                            <input type='text' required/>
                        </div>
                        <div className='upload-cv-container'>
                            <p>Upload resume</p>
                            <label htmlFor="cv" className='lbl-cv'>
                                <img src={process.env.PUBLIC_URL+"/images/uploadicon.svg"} alt='upload-img' />
                                <span>add file</span>
                            </label>
                            <input type='file' name="cv" id='cv' className='input-cv' required style={{display:"none",visibility:"none"}}  />
                        </div>
                        <div className="form-group btnsumbit-container">
                            <button 
                            type="submit" 
                            className="axil-btn btn-fill-black btn-subumit" 
                            name="submit-btn"
                            onClick={handleModel}
                            >
                                Cancel
                            </button>

                            <button type="submit" className="axil-btn btn-fill-primary  btn-subumit" name="submit-btn">Submit form</button>
                        </div> 
                        <div className="form-group">
                            {result ? <Result /> : null}
                        </div>

                    </form>
                
            </div>
                
            

            
             

            
        
    )
}

export default OtherRoles;