import React from 'react'
import TermsData from './TermsData.json';
import TermSubMenu from './TermSubMenu';
function TermSideMenu() {
    const allTermsData = TermsData;
  return (
    <div className='terms-sidemenu-link-container'>
      {
        allTermsData.map((item,index)=>(
            <TermSubMenu item={item} key={index}/>
        ))
      }
    </div>
  )
}

export default TermSideMenu
