import React, { useState } from 'react'
import './priceStyle.scss'
import RangeSliderComponents from '../RangeSliderComponent';

function CardPriceItemData({setSelectedCurrency,selectCurrency,handleTabClick}) {
  // Define a state variable to keep track of the selected option
  const [selectedOption, setSelectedOption] = useState('');
  // eslint-disable-next-line
  const [selectedButton, setSelectedButton] = useState('account'); // Set a default selected option
  const [pay,setPay] =  useState('account_balance')
  const [scale,setScale] =  useState('transaction')
  const [value,setValue] = useState(1000)
  // eslint-disable-next-line
  const [connected,setConnected] = useState('')
  // eslint-disable-next-line
  const [price,setPrice] = useState('')
 
  // eslint-disable-next-line
  const handleData = (event) => {
    setSelectedButton(event.target.value);
  };

  const handleScale = (e)=>{
    setScale(e.target.value)
  }
  const handlePay = (e)=>{
    setPay(e.target.value);
  }
  // Handle the change event when an option is selected
  const handleSelectChange = (event) => {
    const {value}  = event.target
    setSelectedOption(event.target.value);
    setSelectedCurrency(event.target.value);
    if(value === "KES" || value === "GHS"){
      handleTabClick('payment')
    }

  };
  
  const getPay = ()=>{
    switch (pay) {
      case 'account_balance':
        if(selectCurrency==="USD"){

          return 3;
        }
        if(selectCurrency==="CAD"){

          return 2
        }else{
          return '';
        }
   
      case 'transactions':
        if(selectCurrency==="USD"){

          return 3;
        }
        if(selectCurrency==="CAD"){

          return 2
        }else{
          return '';
        }
   
      case 'statement':
        if(selectCurrency==="USD"){

          return 3;
        }
        if(selectCurrency==="CAD"){

          return 2
        }else{
          return '';
        }
   
      case 'credit_report':
        if(selectCurrency==="USD"){

          return 3;
        }
        if(selectCurrency==="CAD"){

          return 2
        }else{
          return '';
        }
   
      case 'identity':
        if(selectCurrency==="USD"){

          return 3;
        }
        if(selectCurrency==="CAD"){

          return 2
        }else{
          return '';
        }
   
      default:
        if(selectCurrency==="USD"){

          return 3;
        }
        if(selectCurrency==="CAD"){

          return 2
        }else{
          return '';
        }
    // Default price when none of the options match
    }
  }

  const calculateDisplayValue = () => {
    if(scale==="account"){
      // eslint-disable-next-line
      if(value==1000){
        // eslint-disable-next-line
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
        // eslint-disable-next-line
      }else if(value == 2000){
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
        // eslint-disable-next-line
      }else if(value == 3000){
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
        // eslint-disable-next-line
      }else if(value == 4000){
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
        // eslint-disable-next-line
      }else if(value == 5000){
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
      }
    }else if(scale === "transaction"){
      // eslint-disable-next-line
      if(value== 1000){
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
        // eslint-disable-next-line
      }else if(value == 2000){
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
        // eslint-disable-next-line
      }else if(value == 3000){
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
        // eslint-disable-next-line
      }else if(value == 4000){
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
        // eslint-disable-next-line
      }else if(value == 5000){
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
      }
    }else if(scale === "statements"){
      // eslint-disable-next-line
      if(value == 1000){
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
        // eslint-disable-next-line
      }else if(value == 2000){
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
        // eslint-disable-next-line
      }else if(value == 3000){
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
        // eslint-disable-next-line
      }else if(value == 4000){
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
        // eslint-disable-next-line
      }else if(value == 5000){
        if(selectCurrency==="USD"){

          return {con:"1000",price:"2,500"}
        }
        if(selectCurrency==="CAD"){

          return {con:"1000",price:"1,500"}
        }
      }
    }
    
    return {con:"", price:""};
};



  // const {displayPrice,displayValue} = calculateDisplayValue();
  return (
    <div className='pricing_data_plan'>
   
      <div className='pricing_data_text'>
        <div className='price_data_top'>
          <h1 className='price_data_h'>Pricing for data plan</h1>
          <p className='price_data_p'>Custom pricing available for companies with large payment volumes</p>
        </div>
        <div className='pricing_data_select'>
          <img src={`/images/${selectCurrency}.svg`} alt='ng' />
          <select 
            value={selectedOption}
            onChange={handleSelectChange}
            className='pricing_select'
          >
          <option value="USD">USD</option>
          <option value="CAD">CAD</option>
          <option value="KES">KES</option>
          <option value="GHS">GHS</option>
          {/* <option value="option1">Option 1</option> */}
          
          </select>
        </div>
      </div>

                {/*  */}

      <div className='price_card_container'>
          <div className='pricing_card'>
              <div className='price_card_wrapper'>
                
                <h5 className='text_pay'>Pay-as-you-go</h5>
                <h1 className='text_api_call'>{(pay!=="account_balance") && <span className='text_api'></span>}<span className='naira_symbol'>{selectCurrency==="CAD"?"$":"$"}</span> {getPay()} <span className='text_api'>MONTHLY / ACCOUNT</span></h1>
                <div className='plan_options'>
                  <div className='input_radio'>
                    <input
                      className='radio'
                      type="radio"
                      name="plan"
                      value="account_balance"
                      checked={pay === 'account_balance'}
                      onChange={handlePay}
                    />
                    <span className={`capped ${(pay === 'account_balance') ? "sel":""}`}>Account Balance</span>
                  </div>
                  
                  <div className='input_radio'>
                    <input
                      type="radio"
                      className='radio'
                      name="plan"
                      value="transactions"
                      checked={pay === 'transactions'}
                      onChange={handlePay}
                    />
                    <span className={`capped ${(pay === 'transactions') ? "sel":""}`}>Transactions</span>
                  </div>
                  <div className='input_radio'>
                    <input
                      type="radio"
                      className='radio'
                      name="plan"
                      value="statement"
                      checked={pay === 'statement'}
                      onChange={handlePay}
                    />
                    <span className={`capped ${(pay === 'statement') ? "sel":""}`}>Statement</span>
                  </div>
                  <div className='input_radio'>
                    <input
                      type="radio"
                      className='radio'
                      name="plan"
                      value="Auth"
                      checked={pay === 'credit_report'}
                      onChange={handlePay}
                    />
                    <span className={`capped ${(pay === 'credit_report') ? "sel":""}`}>Credit Report</span>
                  </div>
                  <div className='input_radio'>
                    <input
                      type="radio"
                      className='radio'
                      name="plan"
                      value="identity"
                      checked={pay === 'identity'}
                      onChange={handlePay}
                    />
                    <span className={`capped ${(pay === 'identity') ? "sel":""}`}>Identity (KYC)</span>
                  </div>
                  
                  <button className='plan_btn first_btn'>
                    Get Started <img src="/images/arrowLeftbtn.svg" alt='started' style={{display:'inline',}} />
                  </button>
                </div>
              </div>
              
          </div>   
            {/* second price card */}
          <div className='pricing_card data_price_card'>
              <div className='price_card_wrapper'>
                
                <h5 className='text_pay'>Scale - Enterprise</h5>
                 {/* eslint-disable-next-line */}
                <p className={`text_api_call ${((scale=="transaction" || scale=="statements") && (value==3000 || value==4000 || value==5000)) ? "font-inc":""}`}><span className='text_api'></span><span className='naira_symbol'>{selectCurrency==="CAD"?"$":"$"}</span> {calculateDisplayValue().price} <span className="text_api">  / MONTH</span></p>
                {/* <img className='slider_bar' src='/images/priceSlider.svg' alt='slider'/> */}
                <RangeSliderComponents value={value} setValue={setValue} scale={scale} calculateDisplayValue={calculateDisplayValue}  />

                <p className='user_price' ><span className='user_price'>{calculateDisplayValue().con}</span> Connected Accounts</p>
                <div className='plan_options'>
                  <div className='input_radio'>
                    <input
                      className='radio'
                      type="radio"
                      name="scale"
                      value="account"
                      checked={scale === 'account'}
                      onChange={handleScale}
                    />
                    <span className={`capped ${(scale === 'account') ? "sel":""}`}>Account Balance</span>
                  </div>
                  
                  <div className='input_radio'>
                    <input
                      type="radio"
                      className='radio'
                      name="scale"
                      value="transaction"
                      checked={scale === 'transaction'}
                      onChange={handleScale}
                    />
                    <span className={`capped ${(scale === 'transaction') ? "sel":""}`}>Transactions</span>
                  </div>
                  <div className='input_radio'>
                    <input
                      type="radio"
                      className='radio'
                      name="scale"
                      value="statements"
                      checked={scale === 'statements'}
                      onChange={handleScale}
                    />
                    <span className={`capped ${(scale === 'statements') ? "sel":""}`}>Statement</span>
                  </div>
                  

                    <div className='plus'>
                      <p className='plus_text'>Plus</p>
                      <div className='plus_item'>
                        <img src='/images/tick.svg' alt='tick'  />
                        <p className='plus_text'>Dedicated Account Management</p>
                      </div>
                      <div className='plus_item'>
                        <img src='/images/tick.svg' alt='tick'  />
                        <p className='plus_text'>Integration Assistance</p>
                      </div>
                      <div className='plus_item'>
                        <img src='/images/tick.svg' alt='tick'  />
                        <p className='plus_text'>Identity & Credit Report (charged per API call)</p>
                      </div>
                    </div>
                  
                  <button className='plan_btn'>
                   Contact Sales <img src="/images/arrowLeftbtn.svg" alt='started' style={{display:'inline',}} />
                  </button>
                </div>
              </div>
              
          </div>

          {/* third card */}
          <div className='pricing_card sec'>
              <div className='price_card_wrapper'>
                
                  <h1 className='price_img_title'>Financial data</h1>
                  <h5 className='price_img_desc'>Pricing Plan</h5>
                  <p className='price_img_sec'>Flick provides transparent, volume-based pricing for all our product</p>  
                  <img className='priceImage' src='/images/priceImage.svg' alt='price' />
              </div>
          </div>
          
      </div>
    </div>
  )
}

export default CardPriceItemData;
