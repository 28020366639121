import React, { useState } from 'react'
import './priceStyle.scss'
import JoinFlick from '../../components/joinflick/JoinFlick';
import Footer from '../../components/footer/Footer';
import CardPriceItemData from './CardPriceItemData';
import CardPricePayment from './CardPricePayment';
import Header from '../../components/merchantLanding/header/Header';
function PricingNew() {
  const [activeTab, setActiveTab] = useState('data');
  const [value, setValue] = useState(1000); // Set the initial value to 1000
  const [selectCurrency,setSelectedCurrency] = useState('USD')
  const handleTabClick = (tab) => {
    

      setActiveTab(tab);
    

  };
 
  
  return (
    <div className='new_pricing'>
   
      <Header />
      <div className='new_pricing_main' >
          <img className='pricing_rainbow' src='/images/pricingRainbow.svg' alt='pricing'/>
        <div className='pricing_main_wrapper'>
              <div className='pricing_content_container'>
                  <div className='pricing_content'>
                    <h3 className='pricing_text'>Tailored pricing plans to suit your needs</h3>
                    <p className='pricing_title'>Favourable pricing to keep you in control of your money </p>
                  </div>
                  <div className='Pricing_btn'>
                    {
                      (selectCurrency === "USD" || selectCurrency === "CAD") &&
                      <span onClick={() => handleTabClick('data')} className={`pricing_btn ${activeTab === 'data' ? 'active' : ''}`}>Data</span>
                    }
                    
                    <span onClick={() => handleTabClick('payment')} className={`pricing_btn ${activeTab === 'payment' ? 'active' : ''}`}>Payment</span>
                  </div>
              </div>


            
              {
                activeTab === 'data' && 
                 <CardPriceItemData value={value} setValue={setValue} setSelectedCurrency={setSelectedCurrency} selectCurrency={selectCurrency} handleTabClick={handleTabClick}/> 
              }
              {
                activeTab === 'payment' && 
                <CardPricePayment setSelectedCurrency={setSelectedCurrency} selectCurrency={selectCurrency}/>
              }
              
          </div>

      </div>
      <div className='join_flick'>
          <JoinFlick />
      </div>

      <Footer />
    </div>
  )
}

export default PricingNew
