import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './JoinFlick.scss';
import JoinFlickSteps from './JoinFlickSteps';
import BuildFlick from './BuildFlick';

function JoinFlick() {
  return (
    <AnimationOnScroll animateIn="fadeInUp" duration={1} delay={200} animateOnce={true}>
    <div className='join-flick-section'>
      {/* <div className='join-flick-left'>
          <div className='join-flick-left-center'>
              <h1>Join Flick business today!</h1>
              <p>Flick is simple to set up. Just download the app and you’re ready to go.</p>
              <a className='get-started-btn' href="https://onelink.to/zzh6zq">
                  Get Started
              </a> 
          </div>
        
      </div> */}
       <BuildFlick />

      <div className='join-flick-right'>
          <div className='join-flick-title'>
            <h1>It only takes flick minutes</h1>
            <img src={process.env.PUBLIC_URL + "/images/flickMinutes.svg"} alt="flickMinutes"/>
          </div>
          <div>
            <JoinFlickSteps
              title="Sign Up"
              description="Join over a thousand businesses using Flick today."
              num="01"
            />
            <JoinFlickSteps
              title="Verify Your Business:"
              description="Complete the KYC process to ensure security."
              num="02"
            />
            <JoinFlickSteps
              title="Go Live"
              description="Transition into the live mode and start using the platform."
              num="03"
              line
            />
          </div>
        

      </div>  
    </div>
    </AnimationOnScroll>
  )
}

export default JoinFlick