import React, { useState } from 'react'
import ProductItem from './ProductItem'
import DataItem from './DataItem'
import WhyItem from './WhyItem'
import './landing.scss'
import { AnimationOnScroll } from 'react-animation-on-scroll'

function Main() {
  const [activeTab, setActiveTab] = useState('payment');
 
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className='landing_main'>
        <div id='products' className='main_wrapper'>
            
            <div className='flick-product-content'>
                <div className='flick-product'>
                    <p className='flick-product-p'>FLICK PRODUCTS</p>
                    <h4 className='flick-product-h'>One platform to access <span>global payments</span> </h4>
                    <h4 className='flick-product-h'>
                    and financial data products
                    </h4>
                    <div className='flick-product-btn'>
                        <p onClick={() => handleTabClick('payment')} className={`flick-product-btn1 ${activeTab === 'payment' ? 'active' : ''}`}>Payments</p>
                        <p onClick={() => handleTabClick('data')} className={`flick-product-btn1 ${activeTab === 'data' ? 'active' : ''}`}>Data</p>
                    </div>
                </div>
                <AnimationOnScroll animateIn="fadeInUp" duration={2} delay={200} animateOnce={true}> 
                <div className={`flick-product-items ${activeTab === 'payment'||  activeTab === 'data' ? 'selected' : ''}`}>
                    {activeTab === 'payment' && (
                        <>
          
                        <ProductItem 
                        img="/images/collections.svg"
                        title="Global Collections"
                        desc="Accept payments via Pay by Bank,  transfers, card and mobile money"
                        />
                        <ProductItem 
                        img="/images/globalNetwork.svg"
                        title="Global Accounts / IBANs"
                        desc="Power multi-currency growth with 15+ Global Accounts / IBANs and 80+ wallets"
                        />

                        <ProductItem 
                        img="/images/payOut.svg"
                        title="Global Payouts"
                        desc="Disburse funds to over 170 countries across the globe"
                        />
                        
                        <ProductItem 
                        img="/images/Clock.svg"
                        title="Real-Time Settlement"
                        desc="Same day settlement in 40+ countries and currencies around the world"
                        />

                        <ProductItem 
                        img="/images/link.svg"
                        title="Payment Links"
                        desc="Receive payments without a single line of code"
                        />

                        <ProductItem 
                        img="/images/recurring.svg"
                        title="Recurring Payments"
                        desc="Accept one-time and recurring payments in multiple currencies"
                        />
                       
                        </>
                    )}
                    {activeTab === 'data' && (
                        <>
                        <ProductItem 
                        img="/images/dataIdentity.svg"
                        title="Identity Verification"
                        desc="Verify KYC, KYB Liveness Check and Onboard customers on the fly"
                        />
                        <ProductItem 
                        img="/images/dataConnect.svg"
                        title="Connect Accounts"
                        desc="Easy account authentication and real-time balance checks "
                        /> 
                        <ProductItem 
                        img="/images/dataTransactions.svg"
                        title="Transactions & Statement"
                        desc="Access periodic transaction history   bank statements in flexible format"
                        />
                        <ProductItem 
                        img="/images/dataIcome.svg"
                        title="Income"
                        desc="Get monthly and annual Income estimates on customer accounts"
                        />
                        <ProductItem 
                        img="/images/dataStatements.svg"
                        title="Credit Report"
                        desc="Retrieve credit data of individuals and businesses across various credit bureaus"
                        />
                        <ProductItem 
                        img="/images/dataAnalytics.svg"
                        title="Analytics"
                        desc="Generate analytics on connected accounts for informed decisions"
                        />
                        </>
                     )} 
                   
                </div>
                </AnimationOnScroll> 
            </div>
            {/*  */}

            <div className='landing-getstarted'>
                <div className='getstarted-content'>
                    <h3 className='getstarted-content-h'>Get started, and  start <span style={{color:"#259792"}}>FLICKING</span> today!</h3>
                </div>
                
                <AnimationOnScroll animateIn="zoomIn" duration={2} delay={200} animateOnce={true}> 
                    <img src='https://qrabaebwebhookbucket.s3.us-east-2.amazonaws.com/rLFeGY0qrXjyfc7lClfOZ/fintech.jpg' alt='hero' />
                </AnimationOnScroll>
            </div>


        </div>
            {/*  */}
        <div id='usecase' className='data-tools'>
            <div className='data-tools-wrapper'>
                <div className='data-tool-content'>
                    <p className='usecase'>USE CASES</p>
                    <h3 className='finance-title'>Payment and Data tools for all use cases</h3>
                    <p className='finance-desc'>Learn how you can make the most of our products</p>
                </div>

                <div className='data-tool-grid'>
                    <DataItem 
                    image="https://qrabaebwebhookbucket.s3.us-east-2.amazonaws.com/YOvl4rxiWqzAEyZVhruFr/trade.png"
                    subtitle="TRADE"
                    title="Payout to suppliers & partners"
                    desc="Use Flick’s streamlined payout solutions to disburse real-time and secure payments to your vendors and partners across the world"
                    />
                    <DataItem 
                    image="https://qrabaebwebhookbucket.s3.us-east-2.amazonaws.com/U5nC2LR_q9JUare8Zcgq5/investing.png"
                    subtitle="INVESTING"
                    title="Multi-currency Payments"
                    desc="Collect funds for equity, fixed income, alternative investments instantly across 80+ currencies."
                    tgBg="#FED9A4"
                    />
                    <DataItem 
                    image="https://qrabaebwebhookbucket.s3.us-east-2.amazonaws.com/DFNe6YzcI1rSbBfbndm_d/checkout-completed.png"
                    subtitle="E-COMMERCE"
                    title="Easy pre-built Checkout"
                    desc="Enhance your customers experience with Flick’s prebuilt checkout solution, making online transactions smooth and hassle-free"
                    tgBg="#DCD5F7"
                    />
                    <DataItem 
                    image="https://qrabaebwebhookbucket.s3.us-east-2.amazonaws.com/qQaJWwKhv_gSPjwWFpZC1/lending.png"
                    subtitle="LENDING"
                    title="Recurring Direct-Debit"
                    desc="Set up recurring payments on single or multiple bank accounts, ensuring timely loan repayment"
                    tgBg="#E0BEF0"
                    />
                    <DataItem 
                    image="https://qrabaebwebhookbucket.s3.us-east-2.amazonaws.com/ML4weD-p7v5vRKTpMdJqT/digital-banking.png"
                    subtitle="DIGITAL BANKING"
                    title="Global Accounts & Wallet"
                    desc="Create multi-currency accounts and wallets for your business and customers seamlessly with a single local entity"
                    tgBg="#FEF0A4"
                    />

                    <DataItem 
                    image="https://qrabaebwebhookbucket.s3.us-east-2.amazonaws.com/rDTkJSWwS3dLTlVl_4g7t/personal-finance.png"
                    subtitle="PERSONAL FINANCE"
                    title="Reliable Financial Data"
                    desc="Help users improve their financial health with aggregated bank accounts, transactions, credit report, insights and analytics"
                    tgBg="#BEE4F0"
                    />
                      
                </div>

                
            </div>
        </div>
        <div className='api-sdk'>
            <div className='api-sdk-wrapper'>

                <div className='api-sdk-img'>
                    <div className='api-sdk-right'>
                        <div className='api-sdk-content'>
                            <h4 className='api-sdk-title'>Built with <br /> Developers in Mind</h4>
                            {/* <div className='api-sdk-btn'>
                                <p className='api-btn api-active'>API</p>
                                <p className='api-btn'>SDK</p>
                            </div> */}
                        </div>
                        <div className='sdk_text'>

                            <div className='sdk-labels'>
                                <h1 className='sdk-headings'>Seamless API</h1>
                                <p className='sdk-paragraphs'>Implement any or all of Flick's products with just a few lines of code in any language</p>
                            </div>
                            <div className='sdk-labels'>
                                <h1 className='sdk-headings'>Customizable Widget </h1>
                                <p className='sdk-paragraphs'>Launch your user-experience with our flexible widget flow</p>
                            </div>
                            <div className='sdk-labels'>
                                <h1 className='sdk-headings'>StandBy Support</h1>
                                <p className='sdk-paragraphs'>Reach-out anytime for technical support or contribute to our community</p>
                            </div>

                            <button className='explore-btn'>
                            Explore our API  <img style={{display:"inline",marginLeft:"6px"}} src="/images/explore.svg" alt='explore'/>
                            </button>
                        </div>
                    </div>
                    <AnimationOnScroll animateIn="fadeInRight" duration={2} delay={200} animateOnce={true}> 
                        <img className='api_code_mobile'  src='/images/apisdk.svg' alt='api' />
                    </AnimationOnScroll>
                </div>
            </div>
        </div>

        {/*  */}

        <div className='why-flick'>
            
            <div className='why-wrapper'>
               

                <div className='why-img'>
                    <img className='land_img_why'  src="/images/whyImg.svg" alt='why'/>
                    <div className='why-right'>
                        <WhyItem  
                        active
                        num="1"
                        title="All-in-One Convenience:" 
                        desc="Simplify your operations by managing payments, financial data and KYC seamlessly within a single interface."
                        />
                        <WhyItem   
                        num="2"
                        title="Expert Support" 
                        desc="Whether you have questions, need technical assistance, or want to explore the full potential of Flick's features, our experts are here to help."
                        />
                        <WhyItem
                        num="3"
                        title="Transparent and Reliable" 
                        desc="Flick will handle your payments securely and efficiently, ensuring that transactions are completed without any hiccups."
                        />
                        
                        <WhyItem
                        line
                        num="4"
                        title="Tailored for Fintech and Beyond" 
                        desc="Whether you're a fintech or an e-commerce platform, Flick's versatile features can be tailored to suit your unique needs."
                        />
                    </div>
                </div>
            </div>
        </div>

        {/*  */}

        <div className='start_building'>
            
            <img className='land_avater' src='/images/ctabg.svg' alt='start'/>
            <div className='avater_content'>
                <h1 className='avater_title'>Start <span>Building</span> today!</h1>
                <p className='avater_desc'>Flick is simple to set up. Just sign up, verify your business and you’re ready to go.</p>
                <a href='https://merchant.getflick.co/signUp' className='avater_btn'>
                    Get started
                    <img style={{marginLeft:"6px"}} className='arrow-btn'  src="/images/arrowS.svg" alt="arrow"/>
                </a>
            </div>
        </div>
       
    </div>
  )
}

export default Main
