import React from 'react'

function AboutValue({title,description,image}) {
  return (
    <div className='value-items-icon'>
      <img src={process.env.PUBLIC_URL + image} alt='values' />
      <div className='value-items-content'>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </div>
  )
}

export default AboutValue
