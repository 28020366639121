import React, { useEffect } from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../../components/footer/Footer';
import Header from '../../components/merchantLanding/header/Header';
import LandingHero from '../../components/merchantLanding/LandingHero';
import Main from '../../components/merchantLanding/Main';
import './merchantLanding.scss'
import { useLocation } from 'react-router-dom';
function MerchantLanding() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
}, [location]);
  return (
    <div className='landing_page'>
      <Header />
      <LandingHero/>
      <div className='web_banner' style={{marginTop:"55px"}}>
        <img src='https://qrabaebwebhookbucket.s3.us-east-2.amazonaws.com/dQUQBWgZgjTOvxXxiG-BQ/banner.jpg' alt=''/>
      </div>
      <div className='mobile_banner' style={{marginTop:"55px"}}>
        <img src='https://qrabaebwebhookbucket.s3.us-east-2.amazonaws.com/reNqR3_0beijFPb5jaMG4/mobile.jpg' alt=''/>
      </div>

      <Main />
      <Footer />
      <ToastContainer />
    </div>
  )
}

export default MerchantLanding;
