import React from 'react'
import CustomerTrust from './CustomerTrust'
import AboutMainItems from './AboutMainItems'
import NewsFeed from '../news/NewsFeed'
import NewsData from '../news/newsData.json';
import JoinFlick from '../joinflick/JoinFlick';
import { AnimationOnScroll } from 'react-animation-on-scroll';

function AboutMain() {
  const allNewsData = NewsData;
  return (
    <div className='about-main'>
       <div className='about-main-container'>
        <CustomerTrust />
        <AboutMainItems 
        images="/images/aboutScanPay.svg"
        title="We're on a mission to unify payments across Africa"
        subtitle="Flick mission"
        description="Our mission at Flick is to connect Africa’s fragmented financial and payment ecosystem by  making it easier and faster for anyone to pay and get paid using any bank or financial provider."
        btntext="Join our team"
        />
        <AboutMainItems 
        images="/images/aboutRuth.svg"
        title="Work alongside amazing people"
        subtitle="We’re hiring"
        description="We're currently not hiring, but thank you for your interest in our company. We appreciate your time and effort. Please check back with us in the future for any potential job openings."
        btntext="Search our open positions"
        reverseRow
        />

        {/* About News section */}
        
        <div className='about-news-section'>
          <div className='about-news-content'>
            <AnimationOnScroll animateIn="slideInLeft" animateOnce={true} delay={300} duration={2}>  
            <h1>We've been recognised by the press</h1>
            <p>Flick provides a comprehensive overview of your finances, revealing insights about your spending habits.</p>
            </AnimationOnScroll>
           
          </div>
          <div className='about-news-feed'>

            {
              allNewsData.slice(0,3).map((data)=>(
                <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={300} duration={2}> 
                  <NewsFeed 
                  image={data.image}
                  date={data.date}
                  read={data.read}
                  title={data.title}
                  description={data.description}
                  />
                </AnimationOnScroll>
              ))
            }
          </div>
        </div>
        {/* Join Flick */}
        <JoinFlick />
       </div>
    </div>
  )
}

export default AboutMain
