import React from 'react'

function BuildFlick() {
  return (
    
      <div className='buildFlick_left'>
          <div className='buildFlick_content'>
              <h5 className='start_build_title'><span className='start_inner'>Start</span> Building <br/> <span className='start_inner'>with</span> Flick <span className='start_inner'>today!</span></h5>
              <p className='start_desc'>Gain access to quality financial data and initiate payment processing directly from bank accounts in a matter of minutes.</p>
              <a className='build_btn' href="https://onelink.to/zzh6zq">
                  Get Started
              </a> 
          </div>
         <img className="start_bg" src='/images/joinBg.svg' alt='bg' />
      </div>
  )
}

export default BuildFlick
