import React from 'react'
import Footer from '../../components/footer/Footer'
import HeroTerms from '../../components/terms/HeroTerms'
import TermSideMenu from '../../components/terms/TermSideMenu'
import '../../components/terms/Terms.scss'
import Header from '../../components/merchantLanding/header/Header'

function TermsCondition() {
    // eslint-disable-next-line
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth' });
    };
  return (
    <div>
      <Header />
      <HeroTerms
      title="Terms and Conditions"
      description="Read our terms below to learn more about your rights and responsibilities as a Flick user."
      subtitle="Last modified: April, 2023"
      />
      <div className='terms-condition-main'>
        <div className="terms-condition-nav">
            <TermSideMenu />
        </div>
        
        <div className='terms-condition-main-content'>
            <section className=' terms-sec' id='introduction'>

                <h1>Introduction</h1>
                <p>
                    These Terms and Conditions are entered into by and between You and QRaba Inc. / Flick. 
                    ("Company," "we," "our,"or "us"). The following terms and conditions, 
                    together with any documents they expressly incorporate by reference (collectively, "Terms and Conditions"), 
                    govern your access to and use of    <a href='https://getflick.app' className='terms-link-color'> https://getflick.app</a> , including any content, functionality, 
                    and services offered on or through <a href='https://getflick.app' className='terms-link-color'> https://getflick.app</a>  (the "Flick App"), whether as a user or a merchant.
                </p>

                <h1>Acceptance of the Terms and Conditions</h1>
                <p>Please read these Terms and Conditions carefully before you start to use the Flick App. By using the Flick App or by clicking to accept or agree to the Terms and Conditions when this option is made available to you, you accept and agree to be bound and abide by these Terms and Conditions and our Privacy Policy, incorporated herein by reference. If you do not want to agree to these Terms and Conditions or the Privacy Policy, you must not access or use the Flick App.</p>
                <h1>Acceptable Age for Usage</h1>
                <p>
                    This Flick App is offered and available to users who are 18 (eighteen) years of age or older. 
                    By using this Flick App, you represent and warrant that you are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. 
                    If you do not meet all of these requirements, you must not access or use the Flick App.
                </p>

                <h1>Changes to the Terms and Conditions</h1>
                <p>
                We may revise and update these Terms and Conditions from time to time in our sole discretion. 
                All changes are effective immediately when we post them, and apply to all access to and use of 
                the Flick App thereafter. However, any changes to the dispute resolution provisions set out in 
                Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual 
                notice on or before the date the change is posted on the Flick App. Your continued use of the Flick 
                App following the posting of any revised Terms and Conditions means that you accept and agree to the changes. 
                You are expected to check this page from time to time as you access the Flick App so you are aware of any changes, 
                as they are binding on you.
                </p>
            </section>
            <section className='terms-sec'  id="accountSecurity">
                <h1>Accessing the Flick App and Account Security</h1>
                <p>
                We reserve the right to withdraw or amend this Flick App, 
                and any service or material we provide on the Flick App, 
                in our sole discretion without notice. We will not be liable 
                if for any reason all or any part of the Flick App is unavailable 
                at any time or for any period. From time to time, we may restrict 
                access to some parts of the Flick App, or the entire Flick App, to users, 
                including registered users.
                <br/>
                You are responsible for both: 
                <li>Making all arrangements necessary for you to have access to the Flick App.</li>
                <li>
                Ensuring that all persons who access the Flick App through your internet 
                connection are aware of these Terms and Conditions and comply with them.
                </li>
                To access the Flick App or some of the resources it offers, you may be asked 
                to provide certain registration details or other information. It is a condition 
                of your use of the Flick App that all the information you provide on the Flick App 
                is correct, current, and complete. You agree that all information you provide to register
                with this Flick App or otherwise, including, but not limited to, through the use of any 
                interactive features on the Flick App, is governed by our Privacy Policy, and you consent 
                to all actions we take with respect to your information consistent with our Privacy Policy.
                If you choose, or are provided with, a user name, password, or any other piece of information 
                as part of our security procedures, you must treat such information as confidential, and you must 
                not disclose it to any other person or entity. You also acknowledge that your account is personal 
                to you and agree not to provide any other person with access to this Flick App or portions of it 
                using your user name, password, or other security information. You agree to notify us immediately 
                of any unauthorized access to or use of your user name or password or any other breach of security. 
                You also agree to ensure that you exit from your account at the end of each session. You should use 
                particular caution when accessing your account from a public or shared computer so that others are 
                not able to view or record your password or other personal information. We have the right to disable 
                any user name, password, or other identifier, whether chosen by you or provided by us, at any time in 
                our sole discretion for any or no reason, including if, in our opinion, you have violated any provision 
                of these Terms and Conditions.
                </p>

                <h1>Intellectual Property Rights</h1>
                <p>
                The Flick App and its entire contents, features, 
                and functionality (including but not limited to all information, 
                software, text, displays, images, video, and audio, and the design, 
                selection, and arrangement thereof) are owned by the Company,
                its licensors, or other providers of such material and are protected 
                by the United States of America, and international copyright, trademark, 
                patent, trade secret, and other intellectual property or proprietary rights laws.
                These Terms and Conditions permit you to use the Flick App for your personal, 
                non-commercial use only except as a Merchant . You must not reproduce, distribute, 
                modify, create derivative works of, publicly display, publicly perform, republish, 
                download, store, or transmit any of the material on our Flick App, except as follows:
                
                <li>
                    Your computer may temporarily store copies of such materials in 
                    RAM incidental to your accessing and viewing those materials.
                </li>
                <li>
                    You may store files that are automatically cached by your Web browser for display enhancement purposes.
                </li>
                <li>
                    You may print or download one copy of a reasonable 
                    number of pages of the Flick App for your own personal, non-commercial use and not for further reproduction, publication, or distribution.
                </li>
                <li>
                    If we provide desktop, mobile, or other applications for download, 
                    you may download a single copy to your computer or mobile device solely 
                    for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.
                </li>
                
                You must not:
                <li>
                    Modify copies of any materials from this site.
                </li>
                <li>
                    Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.
                </li>
                <li>
                    Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.
                </li>

                If you wish to make any use of material on the Flick App other than that set out in this section, please address your request to: info@Flick.app.
                If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Flick App in breach of the Terms and Conditions, your right to use the Flick App will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Flick App or any content on the Flick App is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Flick App not expressly permitted by these Terms and Conditions is a breach of these Terms and Conditions and may violate copyright, trademark, and other laws.
                </p>
            </section>

            
            <section className='terms-sec'  id="trademarks">

            <h1>Trademarks</h1>
            <p>The Company name, ‘Flick’, the Company logo, and all related names, logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company.</p>
            
            <h1>Prohibited Uses</h1>
            <p>
            You may use the Flick App only for lawful purposes and in accordance with these Terms and Conditions. You agree not to use the Flick App:
            <li>
                In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US, Nigeria or other countries).
            </li>
            <li>
                For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.
            </li>
            <li>
                To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail," "chain letter," "spam," or any other similar solicitation.
            </li>
            <li>
                To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).
            </li>
            <li>
                To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Flick App, or which, as determined by us, may harm the Company or users of the Flick App, or expose them to liability.
            </li>

            Additionally, you agree not to:
            <li>
                Use the Flick Appin any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Flick App, including their ability to engage in real time activities through the Flick App.
            </li>
            <li>
                Use any robot, spider, or other automatic device, process, or means to access the Flick App for any purpose, including monitoring or copying any of the material on the Flick App.
            </li>
            <li>
                Use any manual process to monitor or copy any of the material on the Flick App, or for any other purpose not expressly authorized in these Terms and Conditions, without our prior written consent.
            </li>
            <li>
                Use any device, software, or routine that interferes with the proper working of the Flick App.
            </li>
            <li>
                Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.
            </li>
            <li>
                Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Flick App, the server on which the Flick App is stored, or any server, computer, or database connected to the Flick App.
            </li>
            <li>
                Attack the Flick App via a denial-of-service attack or a distributed denial-of-service attack.
            </li>
            <li>
                Otherwise attempt to interfere with the proper working of the Flick App.
            </li>
            </p>
            </section>

            <section className='terms-sec'  id="termination">
            <h1>Monitoring and Enforcement; Termination</h1>
            <p>
            We have the right to:
            <li>
                Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Flick App.
            </li>
            <li>
                Terminate or suspend your access to all or part of the Flick App for any violation of these Terms and Conditions.
            </li>
            Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Flick App. <span>YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY/ANY OF THE FOREGOING PARTIES] DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER [THE COMPANY/SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</span>
            </p>
            <h1>Collection and Usage of Your Information</h1>
            <p>All information we collect on this Flick App is subject to our Privacy Policy. By using the Flick App, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</p>

            <h1>Linking to the Flick App and Social Media Features</h1>
            <p>
            You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our express written consent.
            This Flick App may provide certain social media features that enable you to:
            Link from your own or certain third-party websites to certain content on this Flick App.
            Send emails or other communications with certain content, or links to certain content, on this Flick App.
            Cause limited portions of content on this Flick App to be displayed or appear to be displayed on your own or certain third-party websites.
            You may use these features solely as they are provided by us, and solely with respect to the content they are displayed with. Subject to the foregoing, you must not:
            Establish a link from any Flick App that is not owned by you.
            Cause the Flick App or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking.
            Otherwise take any action with respect to the materials on this Flick App that is inconsistent with any other provision of these Terms and Conditions.

            You agree to cooperate with us in causing any unauthorised framing or linking immediately to stop. We reserve the right to withdraw linking permission without notice.
            We may disable all or any social media features and any links at any time without notice at our discretion.
            </p>
            </section>

            <section className='terms-sec'  id="disclaimer">
                <h1>Disclaimer of Warranties</h1>
                <p>
                You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Flick App will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. 
                <span>
                TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE Flick APP OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE Flick APP OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY Flick APP LINKED TO IT.
                <br/>
                YOUR USE OF THE Flick APP, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE Flick App IS AT YOUR OWN RISK. 
                THE Flick APP, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE Flick APP ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, 
                WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, 
                SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE Flick APP. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE Flick APP, 
                ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE Flick APP WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, 
                OR THAT THE Flick APP OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE Flick APP WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                <br/>
                TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </span>
                
                </p>
                <h1>Limitation on Liability</h1>
                <p>
                TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, 
                OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE 
                LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, 
                OR INABILITY TO USE, THE Flick APP, ANY Flick APPS LINKED TO IT, OR ANY CONTENT ON THE Flick APP, INCLUDING 
                ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, 
                PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS 
                OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), 
                BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
                <br/>
                The limitation of liability set out above does not apply to liability resulting from our gross negligence or wilful misconduct or death or bodily injury caused by products you purchase through the site.
                <br/>
                THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>
                <h1>Indemnification</h1>
                <p>
                    You agree to defend, indemnify, and hold harmless the Company, 
                    its affiliates, licensors, and service providers, and its and 
                    their respective officers, directors, employees, contractors, 
                    agents, licensors, suppliers, successors, and assigns from and 
                    against any claims, liabilities, damages, judgments, awards, losses, 
                    costs, expenses, or fees (including reasonable attorneys' fees) 
                    arising out of or relating to your violation of these Terms and 
                    Conditions or your use of the Flick App, including, but not limited to,
                    your User Contributions, any use of the Flick App's content, services, 
                    and products other than as expressly authorized in these Terms and 
                    Conditions, or your use of any information obtained from the Flick App.
                </p>
                
                <h1>Governing Law and Jurisdiction</h1>
                <p>
                All matters relating to the Flick App and these Terms and Conditions, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Delaware.
                    <br/>
                Any legal suit, action, or proceeding arising out of, or related to, these Terms and Conditions or the Flick App shall be instituted in the federal courts of the United States or the courts of the State of Delaware. We retain the right to bring any suit, action, or proceeding against you for breach of these Terms and Conditions in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.
                </p>
            </section>

            <section className='terms-sec'  id="arbitration">
                <h1>Arbitration</h1>
                <p>
                    At Company's sole discretion, it may require You to submit any 
                    disputes arising from these Terms and Conditions or use of the 
                    Flick App, including disputes arising from or concerning their 
                    interpretation, violation, invalidity, non-performance, or termination, 
                    to final and binding arbitration under the Rules of Arbitration 
                    either of the American Arbitration Association applying Delaware laws or 
                    any Arbitration body in your country of residence.
                </p>

                <h1>Limitation on Time to File Claims</h1>
                <p>
                    ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS AND 
                    CONDITIONS OR THE Flick APP MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; 
                    OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
                </p>

                <h1>Waiver and Severability</h1>
                <p>
                No waiver by the Company of any term or condition set out in these Terms and Conditions shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms and Conditions shall not constitute a waiver of such right or provision.
                <br/>
                If any provision of these Terms and Conditions is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms and Conditions will continue in full force and effect.
                </p>

                <h1>Entire Agreement</h1>
                <p>
                    The Terms and Conditions, and our Privacy Policy constitute 
                    the sole and entire agreement between you and QRaba Inc. / Flick. 
                    regarding the Flick App and supersede all prior and contemporaneous understandings, 
                    agreements, representations, and warranties, both written and oral, regarding the Flick App.
                </p>

                <h1>Your Comments and Concerns</h1>
                <p>
                    This Flick App is operated by QRaba Inc. / Flick. 
                    and its affiliates and subsidiaries. All notices of 
                    copyright infringement claims, feedback, comments, 
                    requests for technical support, and other communications 
                    relating to the Flick App should be directed to: info@Flick.app.
                </p>
            </section>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default TermsCondition
