import React from 'react'

import './JoinFlick.scss'

function JoinFlickSteps({title,description,num,line}) {
  return (
    <div className='join-flick-steps'>
        <div className='join-num'>
          <h3>{num}</h3>
          {!line ? <div className='join-line'></div> : ""}    
        </div>
        <div className='join-flick-content'>
            <h1>{title}</h1>
            <p>{description}</p>
        </div>
    </div>
  )
}

export default JoinFlickSteps