import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import './Faqs.scss'
function Faqs({bgcolor,mt}) {
  return (
    <div className='faqs' style={{backgroundColor:bgcolor,marginTop:mt}}>
        <div className='faq-container'>
            <div className='row faq-container-title'>
                <div className='col-lg-5'>     
                    <AnimationOnScroll animateIn="slideInRight" duration={2} delay={300} animateOnce={true}>
                    <h1 className='faqs-title'>You’ve got questions? We've got answers!</h1>
                    </AnimationOnScroll>
                </div>
            </div>
            <div className='row faq-central-div'>
                <div className='col-lg-6'>
                    <AnimationOnScroll animateIn="zoomIn" duration={2} delay={300} animateOnce={true}>
                        <div className='faqs-div-content'>
                            <h1>When will I receive my money?</h1>
                            <p>You can request for instant deposits from the flick app and get it immediately whenever you  choose</p>
                        </div>
                        <div className='faqs-div-content'>
                            <h1>Who pays the transaction fees?</h1>
                            <p>By default the business bears the transaction fees but you can change this at your convenience from your dashboard</p>
                        </div>
                        <div className='faqs-div-content'>
                            <h1>Do I get charged per transaction?</h1>
                            <p>We charge a payment method fee per transaction. The maximum amount you will pay as transaction fees</p>
                        </div>
                        <div className='faqs-div-content'>
                            <h1>How can I get more information about Flick?</h1>
                            <p>You can read articles in our help center and feel free to contact support at any time.</p>
                        </div>
                    </AnimationOnScroll>
                </div>
                <div className='col-lg-5'>
                    <AnimationOnScroll animateIn="slideInRight" duration={2} delay={300} animateOnce={true}>
                        <img src={process.env.PUBLIC_URL + "/images/faqsImg.svg"} alt='faqs'/>
                    </AnimationOnScroll>
                </div> 
            </div>
        </div>
    </div>
  )
}

export default Faqs