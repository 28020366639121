import React from 'react'
import './News.scss'

function NewsFeed({image,date,read,title,description,link}) {
  return (
    <div className='news-feed-item'>
        <img className='news-img' src={process.env.PUBLIC_URL + image} alt="newsImg" />
        <div className='news-time'>
            <span>{date}</span>
            <span className='news-time-end'>{read}</span>
        </div>
        <div className='news-line'></div>
        <h1>{title}</h1>
        <p>{description}</p>
        <a href={link} className='view-more'>View More</a>
    </div>
  )
}

export default NewsFeed
