import React from 'react'
import './Terms.scss'
function HeroTerms({title,description,subtitle}) {
  return (
    <div className='terms-hero'>
      <div className='terms-hero-container'>
        <span>{subtitle}</span>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
      <img src={process.env.PUBLIC_URL + "/images/ribbonRightBg.svg"} alt="ribbon"/>
    </div> 
  )
}

export default HeroTerms
