import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
// import Landing from "./pages/landing/Landing";
import Merchant from "./pages/merchant/Merchant";
import About from "./pages/about/About";
import Career from "./pages/career/Career";
import News from "./pages/news/News";
import TermsCondition from "./pages/termsCondition/TermsCondition";
import Privacy from "./pages/privacy/Privacy";
import Faqs from "./pages/faqs/Faqs";
import ComingSoon from "./pages/comingSoon/ComingSoon";
import ScanDownload from "./pages/scantodownload/ScanDownload";
import DeleteProfile from "./pages/callToAction/DeleteProfile";
import CallToAction from "./pages/callToAction/CallToAction";
import FlickMiles from "./pages/flickMiles/FlickMiles";
import RoleApplication from "./pages/application/RoleApplication";
import PricingNew from "./pages/pricing/PricingNew";
import MerchantLanding from "./pages/merchantLanding/MerchantLanding";
// import EmailVerification from "./pages/EmailVerification";


function App() {
  return (
    <Router>
      <ScrollToTop >
        <Routes>
          {/* <Route path='/verify-email/:id' element={<EmailVerification /> } /> */}
          <Route path="/" element={<MerchantLanding />} />  
          {/* <Route path="/users" element={<Landing />} />   */}
          <Route path="/merchant" element={<Merchant  />} />  
          <Route path="/about" element={<About/>} />  
          <Route path="/pricing" element={<PricingNew/>} />  
          <Route path="/careers" element={<Career/>} />  
          <Route path="/news" element={<News/>} />  
          <Route path="/terms-conditions" element={<TermsCondition/>} />  
          <Route path="/privacy-policy" element={<Privacy/>} />
          <Route path="/faqs" element={<Faqs/>} />
          <Route path="/comingSoon" element={<ComingSoon/>} />
          <Route path="/scan-download" element={<ScanDownload/>} />
          <Route path="/unsubscribe" element={<CallToAction/>} />
          <Route path="/delete-profile" element={<DeleteProfile/>} />
          <Route path="/flick-miles" element={<FlickMiles/>} />
          <Route path="/applications" element={<RoleApplication/>} />
          {/* <Route path="/range-slide" element={<RangeSliderComponents/>} /> */}
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
