import React from 'react'
import MerchantBenefitsRight from './MerchantBenefitsRight'
import MerchantBenefitsLeft from './MerchantBenefitsLeft'

function MainMerchant() {
  return (
    <div className='merchant-main'>
        <div className='merchant-main-container'>
            <MerchantBenefitsRight
            subtitle="Receive Money"
            title="Convert more sales with faster checkouts"
            description="Make the most of your hard-earned traffic. Faster checkouts convert 1.91x higher with Flick."
            image="/images/merchantbg1.svg"
            icon1='/images/merchantPayLink.svg'
            icon2="/images/merchanNuban.svg"
            icon3="/images/scan.svg"
            icon1Title="Pay Link"
            icon1Desc="Request payments with just a link. Set the amount you want to collect and receive in seconds."
            icon2Title="Dedicated NUBAN"
            icon2Desc="Receive virtual accounts seamlessly for all your company's payments. No paperwork. Instant settlement. No delays."
            icon3Title="Scan and Go"
            icon3Desc="All it takes is just three easy steps: tap, scan and pay. and the best part is no account details are required in the process."
            />

            <MerchantBenefitsLeft
            subtitle="Operate Better"
            title="Operate Smarter with Flick"
            description="Manage your business spend smarter when you use flick to pay."
            image="/images/merchantbg2.svg"
            icon1='/images/merchantMult.svg'
            icon2="/images/merchanNuban.svg"
            icon3="/images/merchantSale.svg"
            icon1Title="Multi-level access for your team "
            icon1Desc="Give specific members of your team exactly the permission they need to do their jobs. And control exactly what they see and do not."
            icon2Title="Get instant overdraft"
            icon2Desc="At Flick, we know that an overdraft can act like a short-term safety net when you’re feeling the burn financially."
            icon3Title="Use data to drive sales"
            icon3Desc="Manage your business more effectively and drive more sales by exploring valuable data insights such as bestselling product/brand."
            />

            <MerchantBenefitsRight
            subtitle="Coming Soon"
            image="/images/merchantbg3.svg"
            icon1='/images/merchantDrive.svg'
            icon2="/images/merchantDrive.svg"
            icon1Title="Drive repeat sales"
            icon1Desc="Drive repeat sales without stress, by providing in-app discounts, coupons, and loyalty cards/points."
            icon2Title="Accelerate customer acquisition"
            icon2Desc="Access our premium network of 25,000+ shoppers to reach high-intent shoppers through our app."
            />
            


        </div>
    </div>
  )
}

export default MainMerchant