import React from 'react'

function CareerTestimonial({image,role,name,desc}) {
  return (
        <>        
            <div className='career-testimonial-container'>
                <div className='career-testimonial-content'>
                    <img src={process.env.PUBLIC_URL + "/images/careerTag.svg"} alt='career'/>
                    <h5>{desc}</h5>
                    <h6>{name}</h6>
                    <span>{role}</span>
                </div>
                <div>
                    <img src={process.env.PUBLIC_URL + image}  alt='testimonail'/>
                </div>
            </div>
            <img src={process.env.PUBLIC_URL + '/images/ellipseTestimonialBg.svg'} alt='testimonial'  className='testimonialbg'/>       
        </>
  )
}

export default CareerTestimonial
