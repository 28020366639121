import React from 'react'
import Footer from '../../components/footer/Footer'
import HeroTerms from '../../components/terms/HeroTerms'
import TermSideMenu from '../../components/terms/TermSideMenu'
import '../../components/terms/Terms.scss'
import Header from '../../components/merchantLanding/header/Header'

function Faqs() {
  return (
    <div className='faqs-terms'>
      <Header />
      <HeroTerms
      subtitle="Last modified: February, 2022"
      title="Frequently Asked Questions"
      description="Learn more about how Flick collects and uses data and your rights as a Flick user."
      />
      <div className='terms-condition-main'>
        <div className="terms-condition-nav">
            <TermSideMenu />
        </div>
     
        <div className='terms-condition-main-content'>
            <section className='faq-introduction'>
              <h1 className='faq-title'>Introduction</h1>

              <div className='faq-desc'>
                <h3>When will I receive my money?</h3>
                <p>You can request for instant deposits from the flick app and get it immediately whenever you  choose</p>
                <h3>Who pays the transaction fees?</h3>
                <p>By default the business bears the transaction fees but you can change this at your convenience from your dashboard</p>
                <h3>Do I get charged per transaction?</h3>
                <p>We charge a payment method fee per transaction. The maximum amount you will pay as transaction fees</p>
                <h3>How can I get more information about Flick?</h3>
                <p>You can read articles in our help center and feel free to contact support at any time.</p>
              </div>
              <h1 className='faq-title'>Contact Information</h1>
              <p>
                To ask questions or comment about this privacy policy and our privacy practices,
                contact us at: <a href='info@getflick.app' className='terms-link-color'>info@getflick.app.</a>
              </p>
            </section>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Faqs
