import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { Link } from 'react-router-dom'

function AboutMainItems({images,title,subtitle,description,btntext,reverseRow}) {
  return (
    <div className={reverseRow ? "main-about-item-reverse":'main-about-items'}>
      <AnimationOnScroll animateIn={reverseRow ? "slideInRight":"slideInLeft"} animateOnce={true} delay={300} duration={2}> 
        <img src={process.env.PUBLIC_URL + images} alt="" />
      </AnimationOnScroll>
      <div className='main-about-items-content'>
        <AnimationOnScroll animateIn={reverseRow ? "slideInLeft":"slideInRight"} animateOnce={true} delay={300} duration={2}> 
            <span>{subtitle}</span>
            <h1>{title}</h1>
            <p>{description}</p>
            <Link to='/careers' className="axil-btn btn-fill-primary">{btntext}</Link>
        </AnimationOnScroll>
       
      </div>
      
    </div>
  )
}

export default AboutMainItems
