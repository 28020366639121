import React from 'react'
import './Merchant.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
function HeroMerchant() {
  return (
    <div className='merchant-hero'>
        <div className='merchant-wrapper'>
            <div className='merchant-hero-container'>
                <h1>Move your Business Faster with Flick</h1>
                <p>Flick is trusted by thousands of businesses, online and offline, to attract, convert, and retain customers through faster payment options and performance-driven marketing.</p>
                <div className='merchant-btn'>
                    {/* <a href='#' className="axil-btn btn-fill-primary">Join as a user</a>
                    <a href='https://flickmerchants.page.link/tV8u' className="axil-btn btn-no-fill">Register as a business</a> */}
                    {/* <a href='https://onelink.to/zzh6zq' className="axil-btn btn-fill-primary">Join as a user</a> */}
                    <a href='/scan-download' className="axil-btn btn-fill-primary">Join as a user</a>
                    <a href='https://onelink.to/sa33jx' className="btn-no-fill">Register as a business</a>

                </div>
            </div>
        </div>
        <img src={process.env.PUBLIC_URL + "/images/ribbonLeftBg.svg"} alt='ribbon' className='ribbon-left'/>
        <img src={process.env.PUBLIC_URL + "/images/ribbonRightBg.svg"} alt='ribbon' className='ribbon-right'/>
        <div className='merchant-slider' >
        <LazyLoadImage
        src={process.env.PUBLIC_URL + '/images/merchant1.svg'}
        height={642}
        width={567}
        effect='blur'
        placeholderSrc='landing-img'
        />
        <LazyLoadImage
        src={process.env.PUBLIC_URL + '/images/merchant2.svg'}
        height={642}
        width={567}
        effect='blur'
        placeholderSrc='landing-img'
        />
        <LazyLoadImage
        src={process.env.PUBLIC_URL + '/images/merchant3.svg'}
        height={642}
        
        effect='blur'
        placeholderSrc='landing-img'
        />      
        </div>
    </div>
  )
}

export default HeroMerchant