import React from 'react'
import './landing.scss'
// eslint-disable-next-line
import Lottie  from 'lottie-react'
// eslint-disable-next-line
import flickBanner from '../../assets/flickBanner.json';
// eslint-disable-next-line
import HeroSpinner from '../../assets/HeroSpinner.json';
function LandingHero() {
  return (
    <div className='landing-hero'>
      <div className='landing-wrapper'>
        <div className='hero-left'>  
            {/*  eslint-disable-next-line */}
            <p className='hero-left-top'>We launched USD Pay by Bank collections 🚀 </p>
            <h3 className='hero-left-text'>
              <span>Open Banking</span> solution powering  <span>data</span> and <br/>real-time <span>global payment</span>
            </h3>
            <h3 className='hero-left-text mobile_text_h'>
              <span>Open Banking</span> solution powering  <span>data</span> and real-time <span>global payment</span>
            </h3>
            <p className='desc-landing'>
            Single connection to access financial data, identity, global accounts and multi-currency payments
            </p>

            <div className='hero-btn'>
            <a href='https://merchant.getflick.co/signUp' className='btn-landing' >
            Get Started <img style={{marginLeft:"6px"}} className='arrow-btn'  src="/images/hero-arrow.svg" alt="arrow"/>
            </a>
            
            </div>
        </div>
        <div className='hero_right'>
          <img src="https://qrabaebwebhookbucket.s3.us-east-2.amazonaws.com/xudRMc_nlFHxLHMhxfHuA/hero-page-map.png" alt=''/>
        
        </div>
        
      </div>
    </div>
  )
}

export default LandingHero
