import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.scss'

function Footer() {
  return (
    <div className='footer'>
            <div className='footer-main'>
                <div className='footer-flick'>
                    <img src={process.env.PUBLIC_URL + '/images/flickFooter.svg'} alt='footer' className='footer-flick-img'/>
                    <p className='footer_secure'>Open banking infrastructure providing access to financial data and real-time Global payment</p>
                    {/* <div className='footer-download'>
                        <a href='https://onelink.to/zzh6zq'>
                            <img src={process.env.PUBLIC_URL + "/images/downLoadBtn.svg"} alt='googlePlay' className='download-link'/>
                        </a>
                        
                    </div> */}
                    <div className='ndpr'>
                       <div className='ndpr_img'>
                            <img src='/images/arm.svg' alt='arm'/>
                            <img src='/images/pci.svg' alt='arm'/>
                       </div>
                      
                    </div>
                </div>

                <div className='footer-widget'>
                    <h1 className='widget-title'>Company</h1>
                    <div className='footer-menu-link'>
                        <ul className="list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/about"}>About us</Link></li>
                            <li> <Link to={process.env.PUBLIC_URL + "/careers"}>Careers</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/pricing"}>Pricing</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/faqs"}>FAQs</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy policy</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/terms-conditions"}>Terms & conditions</Link></li>
                        </ul> 
                    </div>   
                </div>

                <div className='footer-widget'>
                    <h1 className='widget-title'>Developers</h1>
                    <div className='footer-menu-link'>
                        <ul className="list-unstyled">
                            <li><a href="https://docs.getflick.co/docs" target="_blank" rel="noopener noreferrer" >Overview</a></li>
                            <li><a href="https://docs.getflick.co/reference" target="_blank" rel="noopener noreferrer" >API Documentation</a></li>
                            <li><a href="mailto:support@getflick.app" rel="noopener noreferrer">Support</a></li>
                        </ul> 
                    </div>   
                </div>

                <div className='footer-widget footer-widget-addres'>
                    <h1 className='widget-title'>Address</h1>
                  

                        <div className='address'>
                            <img  src={process.env.PUBLIC_URL + "/images/canada.svg"}  alt="flag"/>
                            <p>2967 Dundas St. W. <br/> Toronto ON M6P 1Z2 </p>
                        </div>

                        <div className='address'>
                            <img  src={process.env.PUBLIC_URL + "/images/london.svg"}  alt="flag"/>
                            <p>86-90 Paul St. London <br/>EC2A 4NE</p>
                        </div>
                        
                        <div className='address'>
                            <img  src={process.env.PUBLIC_URL + "/images/usa.svg"}  alt="flag"/>
                            <p>2261 Market St. <br/>San Francisco, CA 94114</p>
                        </div>
                   
                </div>

                
            </div>

            {/*  */}

            <div className='footer-line'></div>

            <div className='footer-aside'>
                <h6 className='copywrite'>© 2023 Flick. All rights reserved</h6>
                <div className='footer-action'>
                    <div className='foot-action-btn'>
                        <Link className='unsubscribe' to="/unsubscribe">Unsubscribe</Link>
                        <Link className='delete'  to="/delete-profile">Delete profile</Link> 
                    </div>
                    <ul className='list-unstyled footer-social-link'>
                        <li><a href="https://www.facebook.com/getflick.africa/" target='_blank'><img src={process.env.PUBLIC_URL + "images/fb.svg"} alt="fb"/></a></li>
                        <li><a href="https://www.instagram.com/getflick.africa/" target='_blank'><img src={process.env.PUBLIC_URL + "images/Instagram.svg"} alt="instagram"/></a></li>
                        <li><a href="https://www.linkedin.com/company/getflick/" target='_blank'><img src={process.env.PUBLIC_URL + "images/Linkdin.svg"} alt="fb"/></a></li>
                        {/* <li><a href="https://www.instagram.com/getflick.africa/"><img src={process.env.PUBLIC_URL + "images/twitter.svg"} alt="fb"/></a></li> */}
                        <li><a href="https://twitter.com/getflick_africa/" target='_blank'><img src={process.env.PUBLIC_URL + "images/twitter.svg"} alt="fb"/></a></li>
                    </ul>            
                </div>
            </div>
        
    </div>
  )
}

export default Footer