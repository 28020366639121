import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import HeroMerchant from '../../components/merchant/HeroMerchant'
import MainMerchant from '../../components/merchant/MainMerchant'
import Faqs from '../../components/faqs/Faqs'
import JoinFlick from '../../components/joinflick/JoinFlick'
import '../../components/merchant/Merchant.scss'

function Merchant() {
  return (
    <div className='merchant'>
        <Header />
        <HeroMerchant />
        <MainMerchant />
        <Faqs 
        bgcolor="#F3FBFB"
        />
        <div className='merchant-join'>
          <JoinFlick />
        </div>
        <Footer />
    </div>
  )
}

export default Merchant