import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import './Form.scss'

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Your Message has been successfully sent.
        </Alert>
    )
}

const FormOne = ({label,btnText}) => { 

    const form = useRef();

    const [ result, showresult ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_yj5dgzp', 'template_hfduayo', form.current, 'WLENsTkBytC0yvItS')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          form.current.reset();
          showresult(true);
      };

        setTimeout(() => {
            showresult(false);
        }, 5000);


    return (
        <form ref={form} onSubmit={sendEmail} >
            <div className="form-group">
                <label>{label}</label>
                <input type="text" className="form-control" name="contact-name" placeholder="Email or phone" required />
            </div>
           
            <div className="form-group">
                <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-subumit" name="submit-btn">{btnText}</button>
            </div> 
            <div className="form-group">
                {result ? <Result /> : null}
            </div>

        </form>
    )
}

export default FormOne;