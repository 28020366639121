import React from 'react'
import { useSwiper } from 'swiper/react'
function SwiperNavButton() {
    const swiper = useSwiper();
  return (
    <div className='swiper-nav-btn'>
      <button onClick={()=>swiper.slidePrev()}>
        <img src={process.env.PUBLIC_URL + "/images/arrow-left.svg"} alt='arrow-left' />
      </button>
      <button onClick={()=>swiper.slideNext()}>
        <img src={process.env.PUBLIC_URL + "/images/arrow-right.svg"} alt='arrow-right' />
      </button>
    </div>
  )
}

export default SwiperNavButton
