import React from 'react'
import Footer from '../../components/footer/Footer'
import './ScanDownload.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Header from '../../components/merchantLanding/header/Header'
function ScanDownload() {
  return (
    <div className='scan-download'>
      <Header />
      
      <div className='scan-main'>
        <div className='scan-content'>
          <h1>Scan QR code, to get Flick anywhere.</h1>
          <p>Scan the QR code with your phone’s camera to download our app.</p>
        </div>
        <div className='scan-flex'>
          
            <div className='scan-left'>
              <LazyLoadImage
              src={process.env.PUBLIC_URL + '/images/scanDownLoad.svg'}    
              effect='blur'
              placeholderSrc='scan'
              />
      
            </div>
            <div className='scan-right'>

              <LazyLoadImage
              src={process.env.PUBLIC_URL + '/images/scanDownLoadSide.svg'}
              effect='blur'
              placeholderSrc='scan'
              />
            </div>
        </div>

        </div>
      
      <Footer />
    </div>
  )
}

export default ScanDownload
