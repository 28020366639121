import React from 'react'
import './Form.scss'
import FormOne from './FormOne'
function FormMain() {
  return (
    <div className='form-section'>
        
        <div className='row form-row'>
            <div className="col-xl-5 col-lg-6 form-left">
                <div className="contact-form-box shadow-box mb--30">
                    <h3 className="form-title">Unsubscribe</h3>                       
                    <FormOne 
                        label="Email or Phone"
                        btnText="Unsubscribe"
                    />
                </div>
            </div>

        </div>
    </div>
  )
}

export default FormMain