import React, { useState } from 'react'
import NewsData from './newsData.json'
import './News.scss'
import NewsFeed from './NewsFeed';
import JoinFlick from '../joinflick/JoinFlick';
function NewsMain() {
    const filter = [
        {
            id:1,
            label: "FUTURED"
        },
        {
            id:2,
            label: "NEWS"
        },
        {
            id:3,
            label: "SUCCESS STORY"
        }

    ]

    const allNewsData = NewsData;

    const [activeFilter, setActiveFilter] = useState("");
   
  const handleClick = (e)=>{
        e.preventDefault();
		let target = e.target.textContent;
        setActiveFilter(target);
   }


  return (
    <div className='news-main'>
        <div className='news-main-container'>
            <div className='news-tab'>
                {filter.map((filter)=>(
                    <button 
                    key={filter.id} 
                    onClick={handleClick}
                    className={filter.label === activeFilter ? "is-checked" : ""}
                    >
                        {filter.label}
                    </button>
                ))}
            </div>
                {/* News feed */}
            <div className='news-feed'>
                {
                    allNewsData.map((data)=>(
                        <NewsFeed 
                        image={data.image}
                        date={data.date}
                        read={data.read}
                        title={data.title}
                        description={data.description}
                        link={data.link}
                        />
                    ))
                }
                
                
            </div>
            <JoinFlick />
        </div>
    </div>
  )
}

export default NewsMain