import React, { useState } from 'react'
import HeroTerms from '../../components/terms/HeroTerms'
import Footer from '../../components/footer/Footer'
import Header from '../../components/merchantLanding/header/Header'

function ComingSoon() {
  const [modal,setModel] = useState(false)
  // eslint-disable-next-line
  const toggleModel = (e)=>{
       
        setModel(!modal)
    }
  return (
    <div>
        <Header />
        <HeroTerms 
            title="Coming Soon"
        />
        
        <Footer />
    </div>
  )
}

export default ComingSoon
