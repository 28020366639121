import React from 'react'
import "./About.scss"
import { Link } from 'react-router-dom'
function HeroAbout() {
  return (
    <div className='hero-about'>
        <div className='hero-about-container'>
        
            <h1>About Flick</h1>
            <p>One platform to earn, spend and grow money better!.</p>
            <Link to='/careers' className="axil-btn btn-fill-primary">Join our team</Link>
        
        </div>
        
        <img className='about-img' src={process.env.PUBLIC_URL + "/images/ribbonRightBg.svg"} alt='about' />
    </div>
  )
}

export default HeroAbout