import React from 'react'
import './News.scss'
function HeroNews() {
  return (
    <div className='news-hero'>
        <div className='news-hero-container'>
            <h1>Flick news room</h1>
            <p>Flick provides a comprehensive overview of your finances, revealing insights about your spending habits.</p>
        </div>
        <img src={process.env.PUBLIC_URL +"/images/ribbonRightBg.svg"} alt="News"/>
    </div>
  )
}

export default HeroNews