import React from 'react'
import FormOne from '../../components/form/FormOne'
import '../../components/form/Form.scss';
import Footer from '../../components/footer/Footer';
import Header from '../../components/merchantLanding/header/Header';

function DeleteProfile() {
  return (
    <div>
        <Header />
        <div className='form-section'>
            <div className='row form-row'>
                <div className="col-xl-5 col-lg-6">
                    <div className="contact-form-box shadow-box mb--30">
                        <h3 className="form-title">Delete My Profile</h3>                       
                        <FormOne
                            label="Email or Phone"
                            btnText="Delete profile"
                        />     
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>      
  )
}

export default DeleteProfile