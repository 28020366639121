import React, { useEffect, useRef, useState } from 'react'
import Alert from 'react-bootstrap/Alert';
import './Form.scss'
import axios from 'axios';

const Result = ({msg}) => {
    return (
        <Alert variant="success" className="success-msg">
            {msg}
        </Alert>
    )
}
function ApplicationForm() {
    const form = useRef();
    const initialValue = {fullname:"",email:"",url:"",position:"",role:""}
    const [ result, showresult ] = useState(false);
    const [file,setFile] = useState("")
    const [inputValues,setInputValues] = useState(initialValue);
    // eslint-disable-next-line
    const [data,setData] = useState([]);
    const [error,setError] = useState("")

    const handleFile =(e)=>{
        setFile(e.target.files[0]);
    }
    const handleChange =(e)=>{
        const { name, value } = e.target;
        setInputValues({
            ...inputValues,
            [name] : value
        })
        
    }
    
   
    const getAllData = async ()=>{
        try{
            const res = await axios.get("https://ayv8r37byg.execute-api.us-east-2.amazonaws.com/production/getData")
            setData(res.data.data);
            
            
        }catch(err){
            console.log(err)
        }
       
        // console.log(datas); 
    }
   useEffect(()=>{
        getAllData(); 
   },[])
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if(file ){
            const formData = new FormData();
            formData.append("file",file);
            formData.append("fullname",inputValues.fullname)
            formData.append("email",inputValues.email)
            formData.append("role",inputValues.role)
            formData.append("url",inputValues.url)
            formData.append("position",inputValues.position)
            try {
                const response =  await axios.post('https://ayv8r37byg.execute-api.us-east-2.amazonaws.com/production/upload',formData);       
                console.log(response.data)   
                form.current.reset();
                setInputValues(initialValue);
                setFile("")
                setError(response.data.msg);
                showresult(true); 
              } catch (error) {
                setError(error)
                console.error('Error uploading file:', error);

              }
        }else{
            console.log("file is empty");
            setError("file is empty")
        }
               
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

   
  return (
    <div>
        <form ref={form} onSubmit={handleSubmit} className='form-role-container' >             
            <div className="form-group1">
                <label className='lblHeading'>FullName *</label>
                <input onChange={handleChange} type="text" className="form-control" name="fullname" placeholder="fullname" required />
            </div>
            <div className="form-group1">
                <label className='lblHeading'>Email *</label>
                <input onChange={handleChange} type="text" className="form-control" name="email" placeholder="email" required />
            </div>
            <div className="form-group1">
                <p className='lblHeading'>Position</p> 
                <label className='other-position'>
                    <input
                    type="radio"
                    value="full time"
                    name="position"
                    checked={inputValues.position === 'full time'}
                    onChange={handleChange}
                    />
                    <span>Full time</span>
                    
                </label>
                
                <label className='other-position'>
                    <input
                    type="radio"
                    value="internship"
                    name="position"
                    
                    checked={inputValues.position === 'internship'}
                    onChange={handleChange}
                    />
                    <span>Internship</span>
                </label>
            </div>
            <div className="form-group1">
                <label className='lblHeading'>Role</label>
                &nbsp;
                <select name="role"style={{borderRadius:"5px",width:"100%",padding:"5px 0"}} value={inputValues.role} onChange={handleChange}>
                    <option value="">-- Select --</option>
                    <option value="design">Design</option>
                    <option value="data analysis">Data Analysis</option>
                    <option value="product management">Product Management</option>
                    <option value="software engineering">Software Engineering</option>
                    <option value="marketing">Marketing</option>
                    <option value="operations">Operations</option>
                    <option value="other">Other</option>     
                </select>
            </div>
            <div className='form-group1'>
                <label>Linkedin/Portfolio URL</label>
                <input name="url" type='text' onChange={handleChange} required/>
            </div>
            <div className='upload-cv-container'>
                <p>Upload resume</p>
                <label htmlFor="cv" className='lbl-cv'>
                    <img src={process.env.PUBLIC_URL+"/images/uploadicon.svg"} alt='upload-img' />
                    <span>add file</span>
                </label>
                <input onChange={handleFile} type='file' name="file" id='cv' className='input-cv' required style={{display:"none",visibility:"none"}}  />
                {file.name}
            </div>
            <div className="form-group btnsumbit-container">
                <button type="submit" className="axil-btn btn-fill-primary  btn-subumit" name="submit-btn">Submit form</button>
            </div> 
            <div className="form-group">
                {result ? <Result msg={error} /> : null}
            </div>
            
        </form>
        {/* {
            data.map((item)=>(
                
                <img style={{width:"50px",height:"50px"}} key={item.id} src={`http://localhost:8500/images/${item.filePathname}`} alt='stan'/>   
                
            )
            )
        } */}
    </div>
  )
}

export default ApplicationForm
