
import React,{useState} from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import '../../components/career/Career.scss'
import Positions from '../../components/career/Positions'
import Faqs from '../../components/faqs/Faqs'
import Footer from '../../components/footer/Footer'
import Value from '../../components/career/Value'
import PositionData from '../../components/career/PositionData.json';
import CareerTestimonial from '../../components/career/CareerTestimonial'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SwiperNavButton from '../../components/career/SwiperNavButton'
import FormPosition from '../../components/form/FormPosition'
import OtherRoles from '../../components/form/OtherRoles'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/merchantLanding/header/Header'

function Career() {
    const navigate = useNavigate();

    // eslint-disable-next-line
    const allData = PositionData
    const [modal,setModel] = useState(false)
    // eslint-disable-next-line
    const [otherRoles,setOtherRoles] = useState(false)
  const toggleModel = (e)=>{
       
        setModel(!modal)
    }
  const toggleOtherRole = (e)=>{
       
        // setOtherRoles(!otherRoles)
        navigate('/applications')
    }
    
  return (
    <div className='career'>
        <Header />
        <main className='career-main'>
            <div className='hero-career'>
                <div className='hero-career-left'>
                    <span className='hiring'>We're hiring</span>
                    <h1>Work alongside <span>amazing</span> peoples</h1>
                    <p>If you're looking for more than just a job, you've come to the right place because we are more than just a company.</p>
                    <a href='#positions' className="axil-btn btn-fill-primary">See past roles</a>
                </div>
                <div className='hero-career-right'>
                    <img src={process.env.PUBLIC_URL + '/images/careerhero.svg'} alt="hero"/>
                </div> 
            </div>

            <div className='career-value'> 
                <div className='career-value-container '>
                        <div className='career-value-content'>
                            <AnimationOnScroll animateIn="slideInRight" duration={2} delay={300} animateOnce={true}>
                                <h1>Our values</h1>
                                <p>Flick provides a comprehensive view of your finances, allowing you to track your spending habits and identify where your money goes each month.</p>
                            </AnimationOnScroll>
                        </div>
                    <div className='row'> 
                        <div className='col-lg-3'>
                            <AnimationOnScroll animateIn="zoomIn" duration={2} delay={300} animateOnce={true}>    
                                <Value
                                image={process.env.PUBLIC_URL + "/images/convenience.svg"}
                                title="Convenience"
                                description="Friction-less digital payments between banks and without barriers"
                                justify="center"
                                align="center"
                                />
                            </AnimationOnScroll>
                        </div>
                        
                        <div className='col-lg-3'>
                            <AnimationOnScroll animateIn="zoomIn" duration={2} delay={300} animateOnce={true}>    
                                <Value
                                image={process.env.PUBLIC_URL + "/images/data.svg"}
                                title="Data"
                                description="Improving lives by helping users leverage their own financial data."
                                justify="center"
                                align="center"
                                />
                            </AnimationOnScroll>
                        </div>
                        <div className='col-lg-3'>
                            <AnimationOnScroll animateIn="zoomIn" duration={2} delay={300} animateOnce={true}>    
                                <Value
                                image={process.env.PUBLIC_URL + "/images/accessibility.svg"}
                                title="Accessibility "
                                description="Making digital payments accessible to everyone anywhere."
                                justify="center"
                                align="center"
                                />
                            </AnimationOnScroll>
                        </div>

                        <div className='col-lg-3'>
                            <AnimationOnScroll animateIn="zoomIn" duration={2} delay={300} animateOnce={true}>    
                                <Value
                                image={process.env.PUBLIC_URL + "/images/innovation.svg"}
                                title="Innovation"
                                description="Creating unique financial experiences for flickers"
                                justify="center"
                                align="center"
                                />
                            </AnimationOnScroll>
                        </div>
                    </div>
                    
                   
                </div>
            </div>

            {/*  */}
            <div className='career-testimonial'>
               
                <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={5}
                slidesPerView="auto"
                
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                
                >
                    {/*  eslint-disable-next-line */}
                <SwiperSlide>
                    <CareerTestimonial 
                    image="/images/careerTestimonia.svg"
                    // eslint-disable-next-line
                    role="For me, working at Getflick.app gives me the perfect balance between structure and freedom. I have a roadmap but I can experiment within it, and I can create my own processes and share them with my team."
                    name="Ruth Ojelade"
                    desc="CEO, Flick"
                    />
                    
                </SwiperSlide>
                <SwiperSlide>
                    <CareerTestimonial 
                    image="/images/Dipo.svg"
                    // eslint-disable-next-line
                    role="Flick provides an incredible work environment that promotes growth and learning. The team is supportive and encourages continuous improvement. I have access to the latest tools and technologies, which helps me stay ahead of the curve in my field. Working at Flick has truly enhanced my skillset and expertise."
                    name="Dipo"
                    desc="CTO, Flick"
                    />   
                    {/* <CareerTestimonialMobile 
                        image="/images/Dipo.jpeg"
                        name="Dipo"
                        role="Flick provides an incredible work environment that promotes growth and learning. The team is supportive and encourages continuous improvement. I have access to the latest tools and technologies, which helps me stay ahead of the curve in my field. Working at Flick has truly enhanced my skillset and expertise."
                        desc="CT0, Flick"
                    />    */}
                </SwiperSlide>
                <SwiperSlide>
                <CareerTestimonial 
                image="/images/tope.svg"
                // eslint-disable-next-line
                role="Flick offers a fantastic work-life balance that allows me to excel both personally and professionally. The company understands the importance of maintaining a healthy lifestyle and supports flexible working arrangements. I appreciate the trust and autonomy given to me, which allows me to manage my time effectively and achieve work-life integration."
                name="Tope"
                desc="Business Operation Executive, Flick" 
                />     
               
                </SwiperSlide>

                <SwiperNavButton />
                </Swiper>
            </div>

            {/* position */}
            <div className='position-main' id="positions">
                <div className='position-container'>
                    <div className='position-title'>
                        <h1>Positions on Flick</h1>
                        <p>We hire the best talent in the industry who share our values, mission, and passion for innovation.</p>
                    </div>
                    <div className='position-item'>
                
                        <Positions
                        opening
                        toggleModel ={toggleModel}
                        title="Marketing 🔥"
                        description="We’ve hired the world best to join our team"
                        apply="Apply for position"
                        active="#259792"
                        color="#FFFFFF"
                        
                        />
                        <Positions
                        title="Senior Software Engineer"
                        description="We’ve hired the world best to join our team"
                        apply="Position is filled"
                        active="#D5F2F0"
                        color="#2EBDB6"
                        />
                        <Positions
                        title="Marketing Manager"
                        description="We’ve hired the world best to join our team"
                        apply="Position is filled"
                        active="#D5F2F0"
                        color="#2EBDB6"
                        />
                        <Positions
                        title="Product Designer"
                        description="We’ve hired the world best to join our team"
                        apply="Position is filled"
                        active="#D5F2F0"
                        color="#2EBDB6"
                        />
                        <Positions
                        title="Customer Success Manager"
                        description="We’ve hired the world best to join our team"
                        apply="Position is filled"
                        active="#D5F2F0"
                        color="#2EBDB6"
                        />
                        <Positions 

                        title="Financial Analyst"
                        description="We’ve hired the world best to join our team"
                        apply="Position is filled"
                        active="#D5F2F0"
                        color="#2EBDB6"
                        />
                        <button className="other-role" onClick={toggleOtherRole}>
                            Don’t see the role you want?
                        </button>
                    </div>
                </div>
            </div>



            {/*  */}
            
            <Faqs bgcolor="#ffffff" />    
        </main>
        {
           modal && 
           <FormPosition toggleModel={toggleModel} /> 
        }
        {
            otherRoles &&
            <OtherRoles toggleOtherRole={toggleOtherRole} />
        }
        <Footer />
    </div>
  )
}

export default Career