import React, { useState } from 'react'
import './priceStyle.scss'
function CardPricePayment({setSelectedCurrency,selectCurrency}) {
    // Define a state variable to keep track of the selected option
    const [selectedOption, setSelectedOption] = useState('');
    const [collection,setCollection] = useState('bank_int')
    const [payout,setPayout] = useState('below')
  
  // Handle the change event when a radio button is selected
 

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedCurrency(event.target.value)
  };

  const handleCollection = (e)=>{
    setCollection(e.target.value)
    console.log(e.target.value)
  }

  const handlePayout = (e)=>{
    setPayout(e.target.value)
  }
  const getPrice = () => {
    switch (collection) {
      case 'bank_local':
        if(selectCurrency==="USD") return 1.5;
        if(selectCurrency==="CAD") return 1.5;
        if(selectCurrency==="GHS") return 1.5;
        if(selectCurrency==="KES") return 1.5;
        return ''

      case 'bank_int':
        // if(selectCurrency==="USD") return 1.5;
        // if(selectCurrency==="CAD") return 1.5;
        if(selectCurrency==="USD") return 0.0;
        if(selectCurrency==="CAD") return 0.0;
        if(selectCurrency==="GHS") return 3.0;
        if(selectCurrency==="KES") return 2.5;
        return ''
      case 'virtual':
        if(selectCurrency==="USD") return 0.5;   
        if(selectCurrency==="CAD") return 0.5;
        // if(selectCurrency==="GHS") return 0.5;
      
        return '';
        
      case 'card':
        return 1.35;
      default:
        if(selectCurrency==="USD"){

          return 1.5;
        }
        if(selectCurrency==="CAD"){

          return 1.5;
        }else{
          return '';
        } // Default price when none of the options match
    }
  };
  const getPayout = () => {
    switch (payout) {
      case 'below':
        if(selectCurrency==="USD") return 5;
        if(selectCurrency==="CAD") return 4;
        if(selectCurrency==="KES") return 90;
        if(selectCurrency==="GHS") return 12.50;
        
        return '';
        
      case 'between':
        if(selectCurrency==="USD"){

          return 25;
        }
        if(selectCurrency==="CAD"){

          return 25;
        }else{
          return '';
        } 
      case 'above':
        return 50;
      case 'instant':
        return 0;
      default:
        return 10; // Default price when none of the options match
    }
  };
  return (
    <div className='pricing_data_plan'>
      
        
        <div className='pricing_data_text'>
            <div className='price_data_top'>
            <h1 className='price_data_h'>Pricing for payment plan</h1>
            <p className='price_data_p'>Custom pricing available for companies with large payment volumes</p>
            </div>
            <div className='pricing_data_select'>
            <img src={`/images/${selectCurrency}.svg`} alt='ng' />
            <select 
                value={selectedOption}
                onChange={handleSelectChange}
                className='pricing_select'
            >
            <option value="USD">USD</option>
            <option value="CAD">CAD</option>
            <option value="KES">KES</option>
            <option value="GHS">GHS</option>
            
            </select>
            </div>
        </div>

                {/*  */}

            {
              (selectCurrency==="USD" || selectCurrency==="CAD") &&
              <div className='price_card_container'>
                  {/* first card */}
                  <div className='pricing_card'>
                      <div className='price_card_wrapper'>
                          
                          <h5 className='text_pay'>Collections</h5>
                          <h1 className='text_api_call'> {getPrice()}% <span className='text_api'>  / TRANSACTION </span></h1>
                          <div className='plan_option'>
                              {/* <div className='input_radio'>
                                  <input
                                  className='radio'
                                  type="radio"
                                  name="collection"   
                                  value="bank_local"
                                  checked={collection === 'bank_local'}
                                  onChange={handleCollection}
                                  /> 
                                  <span className={`capped ${(collection === 'bank_local') ? "sel":""}`}>
                                      Pay by Bank (A2A)  - Local  . 
                                      <br/>
                                      <span className="capped">(capped at N1,000)</span>
                                  </span>
                              </div> */}
                              <div className='card_details'>
                                {/* <div className='input_radio'>
                                    <input
                                    type="radio"
                                    className='radio'
                                    name="collection"
                                    value="bank_int"
                                    checked={collection === 'bank_int'}
                                    onChange={handleCollection}
                                    />
                                    <span className={`capped ${(collection === 'bank_int') ? "sel":""}`}>
                                        Pay by Bank
                                        <br/>
                                        <span className="capped">{selectCurrency==="CAD"?'(base $1.50)':'(base $3)'}</span>
                                    </span>
                                </div> */}
                                <div className='input_radio'>
                                    <input
                                    type="radio"
                                    className='radio'
                                    name="collection"
                                    value="virtual"
                                    checked={collection === 'virtual'}
                                    onChange={handleCollection}
                                    />
                                    <span className={`capped ${(collection === 'virtual') ? "sel":""}`}>
                                        Global Account 
                                        <br/>
                                        <span className="capped">{selectCurrency==="CAD"?'(base $2)':'(base $2)'}</span>
                                    </span>
                                </div>
                              </div>
                              {/* <div className='input_radio'>
                                  <input
                                  type="radio"
                                  className='radio'
                                  name="collection"
                                  value="card"
                                  checked={collection === 'card'}
                                  onChange={handleCollection}
                                  />
                                  <span className={`capped ${(collection === 'card') ? "sel":""}`}>
                                      Card - 
                                      <br/>
                                      <span className="capped">(capped at N2,000)</span>
                                  </span>
                              </div> */}
                        
                          
                              <button  className='plan_btn first_btn'>
                                  Get Started <img src="/images/arrowLeftbtn.svg" alt='started' style={{display:'inline',}} />
                              </button>
                          </div>
                      </div>
                      
                  </div>



                  {/* second price card */}
                  <div className='pricing_card'>
                      <div className='price_card_wrapper'>
                          
                          <h5 className='text_pay'>Payout</h5>
                          <h1 className='text_api_call'><span className='naira_symbol'>$</span> {getPayout()} </h1>
                          <div className='plan_option'>
                            


                                  <div className='input_radio'>
                                      <input
                                      className='radio'
                                      type="radio"
                                      name="payout"
                                      value="below"
                                      checked={payout === 'below'}
                                      onChange={handlePayout}
                                      />
                                      <span className={`capped ${(payout === 'below') ? "sel":""}`}>
                                        Local
                                      <br/>
                                      <span className="capped">{selectCurrency==="CAD"?'(within Canada)':'(within US)'}</span>
                                      </span>
                                  </div>
                            
                                  <div className='input_radio'>
                                      <input
                                      type="radio"
                                      className='radio'
                                      name="payout"
                                      value="between"
                                      checked={payout === 'between'}
                                      onChange={handlePayout}
                                      />
                                      <span className={`capped ${(payout === 'between') ? "sel":""}`}>SWIFT</span>
                                  </div>
                           
                          {/* <div className='input_radio'>
                              <input
                              type="radio"
                              className='radio'
                              name="payout"
                              value="above"
                              checked={payout === 'above'}
                              onChange={handlePayout}
                              />
                              <span className={`capped ${(payout === 'above') ? "sel":""}`}>Above N50,000</span>
                          </div>
                          <div className='input_radio'>
                              <input
                              type="radio"
                              className='radio'
                              name="payout"
                              value="instant"
                              checked={payout === 'instant'}
                              onChange={handlePayout}
                              />
                              <span className={`capped ${(payout === 'instant') ? "sel":""}`}>Instant Settlement (wallet)</span>
                          </div> */}
                              <button className='plan_btn first_btn payment_btn'>
                                  Get Started <img src="/images/arrowLeftbtn.svg" alt='started' style={{display:'inline',}} />
                              </button>
                          </div>

                          
                          
                      </div>
                      
                  </div>



                      
                    

                  {/* third card */}
                  <div className='pricing_card sec'>
                      <div className='price_card_wrapper'>    
                          <h1 className='price_img_title'>Payment</h1>
                          <h5 className='price_img_desc'>Pricing Plan</h5>
                          <p className='price_img_sec'>For custom plans and volume pricing, contact hello@getflick.app</p>  
                          <img className='priceImage' src='/images/priceImage.svg' alt='price' />
                      </div>
                  </div>
              </div>
            }

            {/* ============= For Ghana and KES ===============*/}

            {
              (selectCurrency === "GHS" || selectCurrency==="KES") &&
              <div className='price_card_container'>
                  {/* first card */}
                  <div className='pricing_card'>
                      <div className='price_card_wrapper'>
                          
                          <h5 className='text_pay'>Collections</h5>
                          <h1 className='text_api_call'> {getPrice()}% </h1>
                          <div className='plan_option'>
                              {/* <div className='input_radio'>
                                  <input
                                  className='radio'
                                  type="radio"
                                  name="collection"   
                                  value="bank_local"
                                  checked={collection === 'bank_local'}
                                  onChange={handleCollection}
                                  /> 
                                  <span className={`capped ${(collection === 'bank_local') ? "sel":""}`}>
                                      Pay by Bank (A2A)  - Local  . 
                                      <br/>
                                      <span className="capped">(capped at N1,000)</span>
                                  </span>
                              </div> */}
                          
                              <div className='input_radio'>
                                  <input
                                  type="radio"
                                  className='radio'
                                  name="collection"
                                  value="bank_int"
                                  checked={collection === 'bank_int'}
                                  onChange={handleCollection}
                                  />
                                  <span className={`capped ${(collection === 'bank_int') ? "sel":""}`}>
                                      Mobile Money 
                                    
                                      {/* <span className="capped">{selectCurrency==="CAD"?'(base $1.50)':'(base $3)'}</span> */}
                                  </span>
                              </div>
                              
                        
                          
                              <button  className='plan_btn first_btn'>
                                  Get Started <img src="/images/arrowLeftbtn.svg" alt='started' style={{display:'inline',}} />
                              </button>
                          </div>
                      </div>
                      
                  </div>



                  {/* second price card */}
                  <div className='pricing_card'>
                      <div className='price_card_wrapper'>
                          
                          <h5 className='text_pay'>Payout</h5>
                          <h1 className='text_api_call'><span className='naira_symbol'>{selectCurrency==="KES"?"KSh":'GH₵'}</span> {getPayout()} </h1>
                          <div className='plan_option2'>
                          <div className='input_radio'>
                              <input
                              className='radio'
                              type="radio"
                              name="payout"
                              value="below"
                              checked={payout === 'below'}
                              onChange={handlePayout}
                              />
                              <span className={`capped ${(payout === 'below') ? "sel":""}`}>
                                Flat fee
                              <br/>
                              {/* <span className="capped">{selectCurrency==="CAD"?'(within Canada)':'(within US)'}</span> */}
                              </span>
                          </div>
                          
                              <button className='plan_btn first_btn payment_btn'>
                                  Get Started <img src="/images/arrowLeftbtn.svg" alt='started' style={{display:'inline',}} />
                              </button>
                          </div>

                          
                          
                      </div>
                      
                  </div>



                      
                    

                  {/* third card */}
                  <div className='pricing_card sec'>
                      <div className='price_card_wrapper'>    
                          <h1 className='price_img_title'>Payment</h1>
                          <h5 className='price_img_desc'>Pricing Plan</h5>
                          <p className='price_img_sec'>For custom plans and volume pricing, contact hello@getflick.app</p>  
                          <img className='priceImage' src='/images/priceImage.svg' alt='price' />
                      </div>
                  </div>
              </div>
            }
    </div>
  )
}

export default CardPricePayment
