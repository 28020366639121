import React from 'react'
import Footer from '../../components/footer/Footer'
import ApplicationForm from '../../components/form/ApplicationForm'
import './RoleApplication.scss'
import Header from '../../components/merchantLanding/header/Header'

function RoleApplication() {
  return (
    <div className='role-application'>
        <Header />
        <div className='form-role-main'>
            <ApplicationForm />
        </div>
        <Footer />
    </div>
  )
}

export default RoleApplication
