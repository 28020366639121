import React from 'react'
import Footer from '../../components/footer/Footer'
import HeroTerms from '../../components/terms/HeroTerms'
import Header from '../../components/merchantLanding/header/Header'

function FlickMiles() {
  return (
    <div className='flick-miles'>
      <Header />
      <HeroTerms 
      title="Flick Miles"
      />
      
      <Footer />
    </div>
  )
}

export default FlickMiles
