import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import MerchantBenefitsIcons from './MerchantBenefitsIcons'


function MerchantBenefitsLeft(
    {
        subtitle,
        title,
        description,
        image,
        icon1,
        icon2,
        icon3,
        icon1Title,
        icon2Title,
        icon3Title,
        icon1Desc,
        icon2Desc,
        icon3Desc,      
    }
) {
    return (
        <div className='merchant-benefit'>
            <div className='merchant-benefit-container'>
                <div className='col-lg-6 col-small-screen'>
                   
                        <div className='benefit-image left-spacing'>
                            <img className='merchant-benefit-img' src={process.env.PUBLIC_URL + image} alt="benefit" />
                        </div>
                    
                </div>
                {/* content */}
                <div className='merchant-benefit-content-wrapper'>
                    <AnimationOnScroll animateIn="zoomIn" animateOnce={true} delay={100}>
                        <div className='merchant-benefit-content'>
                            <span>{subtitle}</span>
                            {
                                title && <>
                                <h1>{title}</h1>
                                <p>{description}</p>
                                </>
                            }
                        </div>
        
                        <div className='merchant-benefit-icons'>
                            {/* component will go here */}
                            <MerchantBenefitsIcons 
                                title={icon1Title}
                                desc={icon1Desc}
                                icon={icon1}
                            />
                            <MerchantBenefitsIcons 
                                title={icon2Title}
                                desc={icon2Desc}
                                icon={icon2}
                            />
                            {icon3 && <MerchantBenefitsIcons 
                                title={icon3Title}
                                desc={icon3Desc}
                                icon={icon3}
                            />
                            }
                            
                            
                        </div>
                    </AnimationOnScroll>
                </div>
                
            </div>
        </div>
      )
}

export default MerchantBenefitsLeft