import React from 'react'
import Footer from '../../components/footer/Footer'
import HeroAbout from '../../components/about/HeroAbout'
import '../../components/about/About.scss'
import AboutValue from '../../components/about/AboutValue'
import AboutMain from '../../components/about/AboutMain'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import Header from '../../components/merchantLanding/header/Header'

function About() {
  return (
    <div className='about'>
        <Header />
        <HeroAbout />
        <div className='about-value'>
            <div className='about-value-container'>

                <div className='about-value-content'>
                    <AnimationOnScroll animateIn="slideInLeft" duration={2} delay={300} animateOnce={true}>
                        <h1>Our values</h1>
                        <p>Flick provides a comprehensive view of your finances, allowing you to track your spending habits and identify where your money goes each month.</p>
                    </AnimationOnScroll>
                </div>
                <div className='about-images'>
                    <div className='value-item'>
                        <AnimationOnScroll animateIn="fadeInUp" duration={2} delay={300} animateOnce={true}>
                            <AboutValue
                            title="Convenience"
                            description="Friction-less digital payments between banks and without barriers"
                            image="/images/convenience.svg"
                            />
                        </AnimationOnScroll>
                        
                        <AnimationOnScroll animateIn="fadeInUp" duration={2} delay={300} animateOnce={true}>
                            <AboutValue
                            title="Data"
                            description="Improving lives by helping users leverage their own financial data."
                            image="/images/data.svg"
                            />
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="fadeInUp" duration={2} delay={300} animateOnce={true}>
                            <AboutValue
                            title="Accessibility "
                            description="Making digital payments accessible to everyone anywhere."
                            image="/images/accessibility.svg"
                            />
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="fadeInUp" duration={2} delay={300} animateOnce={true}>

                            <AboutValue
                            title="Innovation"
                            description="Creating unique financial experiences for flickers"
                            image="/images/innovation.svg"
                            />
                        </AnimationOnScroll>
                        
                    </div>
                    <AnimationOnScroll animateIn="slideInRight" duration={2} delay={300} animateOnce={true}>
                        <img src={process.env.PUBLIC_URL + "/images/aboutValue.svg"} alt='about' />
                    </AnimationOnScroll>
                </div>
            </div>
            <img src={process.env.PUBLIC_URL+"/images/EllipseAbout.svg"} alt="about" className='aboutRibbon-img'/>
        </div>
        <AboutMain />
        <Footer />
    </div>
  )
}

export default About