import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
import './Header.scss'


const Navbar = () => {

    
  const [activeFilter, setActiveFilter] = useState("");

  const handleClick = (filter) => {
    setActiveFilter(filter);
  };
    return (
        <nav className="mainmenu-nav">
            <ul className="main_menu">      
                <li>
                    <Link 
                    to={process.env.PUBLIC_URL + "/merchant"}
                    onClick={() => handleClick("Business")}
                    className={activeFilter === "Business" ? "active" : ""}
                    >
                        Business
                    </Link>
                </li>
                <li>
                    <Link 
                    to={process.env.PUBLIC_URL + "/pricing"}
                    onClick={() => handleClick("Pricing")}
                    className={activeFilter === "Pricing" ? "active" : ""}
                    >
                        Pricing
                    </Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">About Us<FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/about"}>About us</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/careers"}>Careers on Flick</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/news"}>Press</Link></li>           
                        {/* <li><Link to={process.env.PUBLIC_URL + "/flick-miles"}>Flick-miles</Link></li>            */}
                    </ul>
                </li> 
                
                z
                <li className="menu-item-has-children">
                    <Link to="#">Developer<FaAngleDown /></Link>
                    <ul className="axil-submenu">
                            <li><a href="https://docs.getflick.co/docs" target="_blank" rel="noopener noreferrer" >Overview</a></li>
                            <li><a href="https://docs.getflick.co/reference" target="_blank" rel="noopener noreferrer" >API Documentation</a></li>
                    </ul>
                </li>
                
                <a href='https://onelink.to/zzh6zq' className="axil-btn btn-fill-primary btn-left">Join Flick</a>
                
            </ul>
            
        </nav>
        
    )
}

export default Navbar;