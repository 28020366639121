import React from 'react'
import HeroNews from '../../components/news/HeroNews'
import NewsMain from '../../components/news/NewsMain'
import '../../components/news/News.scss'
import Footer from '../../components/footer/Footer'
import Header from '../../components/merchantLanding/header/Header'

function News() {
  return (
    <div className='news'>
        <Header />
        <HeroNews />
        <NewsMain />
        <Footer />
    </div>
  )
}

export default News