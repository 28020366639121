import React from 'react'
import './Career.scss'
function Positions({title,description,active,apply,color,toggleModel,opening}) {
  const handleToggleModel =()=>{
    toggleModel();
  }
  return (
    <div className='position'>
        <div className='position-left'>
            <h1>{title}</h1>
            <p>{description}</p>
        </div>
        <div className='position-right'>
            <button 
            
            onClick={opening ? handleToggleModel:""} 
            style={{backgroundColor:active,color:color}}
            >
              {apply}
            </button>
        </div>
    </div>
  )
}

export default Positions