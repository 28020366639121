import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';



const RangeContainer = styled.div`
  height: 32px;
  max-width:  277px;
 
  border-radius: 4rem;
  display: grid;
`;

const RangeContent = styled.div`
  position: relative;
  width: 277px;
  display: grid;
  place-items: center;
  
`;

const RangeSlider = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: var(--gray-200, #EAECF0);
  overflow: hidden;
`;

const RangeSliderLine = styled.div`
  width: ${({ width }) => width}%;
  height: 100%;
  border-radius: 4px;
  background: var(--primary-600, #259792);
  
`;

const RangeThumb = styled.div`
  width: 24px;
  height: 24px;
  background-color: hsl(256, 64%, 88%);;
  border-radius: 50%;
  border-radius: 12px;
    border: 1.5px solid var(--primary-600, #259792);
    background: var(--base-white, #FFF);
  box-shadow: 0 0 12px hsla(256, 72%, 24%, 0.2);
  position: absolute;
  left: ${({ left }) => left}px;
  
`;
// eslint-disable-next-line
const RangeValue = styled.div`
  width: 64px;
  height: 64px;
  background: linear-gradient(95deg,
    hsl(286, 97%, 57%) -7%,
    hsl(256, 84%, 48%) 112%);;
  position: absolute;
  top: -82px;
  left: ${({ left }) => left}px;
  border-radius: 2rem 2rem 2rem 0.25rem;
  transform: rotate(-45deg);
  display: grid;
  place-items: center;

`;
// eslint-disable-next-line
const RangeValueNumber = styled.span`
  transform: rotate(45deg);
  color: var(--white-color);
  font-size: var(--h1-font-size);
`;

const RangeInput = styled.input`
  appearance: none;
  width: 100%;
  height: 16px;
  position: absolute;
  opacity: 0;
`;

function RangeSliderComponents({value,setValue,scale,calculateDisplayValue}) {
   
    const [space, setSpace] = useState(0);
    const rangeInputRef = useRef(null);
  
    useEffect(() => {
      // Calculate the space once when the component mounts
      const rangeInput = rangeInputRef.current;
      if (rangeInput) {
        setSpace(rangeInput.offsetWidth - 32); // 32 is the width of the thumb
      }
    }, []);

    // const calculateDisplayValue = () => {
    //     let displayValue = 0;
    //     let displayPrice = 0;
    
    //     switch (scale) {
    //       case 'account':
    //         switch (value) {
                
    //           case 1000:
    //             console.log(value)
    //             displayValue = 1000;
    //             displayPrice = 15000;
    //             console.log("1000")
                
    //             break;
    //           case 2000:
    //             displayValue = 2500;
    //             displayPrice = 30000;
    //             console.log("2000")

    //             break;
    //           case 3000:
    //             displayValue = 5000;
    //             displayPrice = 50000;
               
    //             console.log("3000")
    //             break;
    //           case 4000:
    //             displayValue = 7500;
    //             displayPrice = 67500;
    //             console.log("4000")
              
    //             break;
    //           case 5000:
    //             displayValue = 10000;
    //             displayPrice = 80000;
               
    //             break;
    //           default:
    //             displayPrice = 'above price';
    //             setDisplayValue(displayValue)
    //             setDisplayPrice(displayPrice)
    //             break;
    //         }
    //         break;
    //       case 'transaction':
    //         switch (value) {
    //           case 1000:
    //             displayValue = 1000;
    //             displayPrice = 70000;
               
    //             break;
    //           case 2000:
    //             displayValue = 2500;
    //             displayPrice = 150000;
                
    //             break;
    //           case 3000:
    //             displayValue = 5000;
    //             displayPrice = 275000;
                
    //             break;
    //           case 4000:
    //             displayValue = 7500;
    //             displayPrice = 375500;
                
    //             break;
    //           case 5000:
    //             displayValue = 10000;
    //             displayPrice = 450000;
                
    //             break;
    //           default:
    //             displayPrice = 'above price';
                
    //             break;
    //         }
    //         break;
    //       case 'statements':
    //         switch (value) {
    //           case 1000:
    //             displayValue = 1000;
    //             displayPrice = 130000;
                
    //             break;
    //           case 2000:
    //             displayValue = 2500;
    //             displayPrice = 275000;
                
    //             break;
    //           case 3000:
    //             displayValue = 5000;
    //             displayPrice = 500000;
                
    //             break;
    //           case 4000:
    //             displayValue = 7500;
    //             displayPrice = 712500;
                
    //             break;
    //           case 5000:
    //             displayValue = 10000;
    //             displayPrice = 900000;
                
    //             break;
    //           default:
    //             displayPrice = 'above price';
                
    //             break;
    //         }
    //         break;
    //       default:
    //         break;
    //     }
        
    //     return { displayValue, displayPrice };
    // };
  
    const handleInputChange = (e) => {
      const newValue = e.target.value;
      setValue(newValue);
      calculateDisplayValue();
    };
  
    return (
  
        <RangeContainer>
            
          <RangeContent>
            <RangeSlider>
              <RangeSliderLine width={(value / 5000) * 100} /> {/* Adjust the width calculation */}
            </RangeSlider>
            <RangeThumb left={(value / 5000) * space} /> {/* Adjust the left calculation */}
            {/* <RangeValue left={(value / 5000) * space}>
              <RangeValueNumber>{value}</RangeValueNumber>
            </RangeValue> */}
            <RangeInput
            type="range"
            min="1000" // Minimum value
            max="5000" // Maximum value
            step="1000" // Step value (1000, 2500, 5000, 7500, 10000)
            value={value}
            onChange={handleInputChange}
            id="range-input"
            ref={rangeInputRef}
            />
          </RangeContent>
        </RangeContainer>
     
    );
  
}

export default RangeSliderComponents
