import React from 'react'

function DataItem({image,subtitle,title,desc,tgBg='#BEF0C7'}) {
  return (
    <div className='data-tool-item'>
        <img src={image} alt='data' />
        {/* <p className='data-subtitle'>{subtitle}</p> */}
        <div className='tag_tool' style={{background:tgBg}}>
          <p className='tag_text'>{subtitle}</p>
        </div>
        <h5 className='data-title'>{title}</h5>
        <p className='data-desc'>{desc}</p>
    </div>
  )
}

export default DataItem
