import React from 'react'
import './Merchant.scss'

function MerchantBenefitsIcons({title,desc,icon}) {
  return (
    <div className='icon-benefits'>
        <img className='icon-benefits-img' src={process.env.PUBLIC_URL + icon} alt="icon" />
        <div className='icon-benefits-text'>
            <h1>{title}</h1>
            <p>{desc}</p>
        </div>
    </div>
  )
}

export default MerchantBenefitsIcons