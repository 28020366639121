import React from 'react'
import './Career.scss'
function Value({title,image,description,justify,align}) {
  return (
    <div className='value' style={{justifyContent:justify,alignItems:align}}>
        <img className='value-image' src={process.env.PUBLIC_URL + image} alt="number" />
        <h1>{title}</h1>
        <p>{description}</p>
    </div>
  )
}

export default Value